import React, { useContext, useEffect, useRef, useState } from 'react';
import 'Styles/LandingPage/landing.css';
import RefContext from 'Utilities/refContext';
import { createKey } from 'Utilities/helpers';
import rating from 'Assets/rating.svg';
import calender from 'Assets/lightCalender.svg';
import userthree from 'Assets/lightUsersthree.svg';
import seperator from 'Assets/ellipseSeperator.svg';
import verification from 'Assets/landingverification.svg';
import arrow from 'Assets/landingArrow.svg';
import { useNavigate } from 'react-router-dom';
import previousClients1 from 'Assets/PreviousClients/previousClients1.svg';
import previousClients2 from 'Assets/PreviousClients/previousClients2.svg';
import previousClients3 from 'Assets/PreviousClients/previousClients3.svg';
import previousClients4 from 'Assets/PreviousClients/previousClients4.svg';
import previousClients5 from 'Assets/PreviousClients/previousClients5.svg';
import { onImageError } from 'Utilities/helpers';

const AgencyScroll = () => {
 //********************************************************Constants***************************************************//

 const scrollContainerRef = useRef(null);
 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { getAgencyList } = actions;
 const { agencyList } = store;
 const navigate = useNavigate();
 const clients = [
  previousClients1,
  previousClients2,
  previousClients3,
  previousClients4,
  previousClients5
 ];

 const [influencerIsHovered, setInfluencerIsHovered] = useState(false);
 const [service, setService] = useState([]);

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  getAgencyList(1, 'sort[0]=techreviewerRating');
 }, []);

 useEffect(() => {
  const scrollContainer = scrollContainerRef.current;
  const fastScrollStep = 2;
  const slowScrollStep = 1;
  const delay = 20;

  const scroll = () => {
   const scrollStep = influencerIsHovered ? slowScrollStep : fastScrollStep;
   if (scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth) {
    scrollContainer.scrollLeft = 0;
   } else {
    scrollContainer.scrollLeft += scrollStep;
   }
  };

  const scrollInterval = setInterval(scroll, delay);

  return () => clearInterval(scrollInterval);
 }, [influencerIsHovered]);

 useEffect(() => {
  if (agencyList) {
   let addedServices = false;
   agencyList?.data?.forEach((element) => {
    if (!addedServices) {
     element?.servicesFocus?.forEach((data) => {
      if (data?.services?.length >= 1 && !addedServices) {
       setService([...data?.services]);
       addedServices = true;
       return;
      }
     });
    }
   });
  }
 }, [agencyList]);

 //********************************************************Functions***************************************************//

 //Navigate to particular agency detail page
 const handleRouter = (category, companename, id) => {
  const formattedServiceType = category?.replace(/\s+/g, '-').toLowerCase();
  const formattedCompanyName = companename?.replace(/\s+/g, '-').toLowerCase();

  navigate(`/top-${formattedServiceType}-companies/${formattedCompanyName}`, {
   state: {
    name: { title: `Top ${category} Companies`, category: category },
    id: id
   }
  });
 };

 //********************************************************html***************************************************//

 return (
  <div className="card-carousel">
   <div ref={scrollContainerRef} className="card-carousel-container">
    <div className="landing-card-carousel">
     {agencyList?.data?.map(
      (card, index) =>
       card?.servicesFocus?.[0]?.services?.length > 1 && (
        <div
         onClick={() =>
          handleRouter(card?.servicesFocus?.[0]?.category, card?.companyName, card?.id)
         }
         onMouseEnter={() => setInfluencerIsHovered(true)}
         onMouseLeave={() => setInfluencerIsHovered(false)}
         key={createKey(index, card?.id)}
         className="landing-scroll-card-container">
         <div className="agency-logo-container">
          <img onError={onImageError} src={card?.logoUrl} alt="logo" />
         </div>
         <div className="agency-scroll-card-content">
          <div className="agency-scroll-card-ratings">
           <div className="scroll-card-rating-image">
            <img src={rating} alt="rating" />
           </div>
           <div className="scroll-card-rating-number">{card?.techreviewerRating}</div>
          </div>
          <div className="scroll-card-company-details-container">
           <div className="company-details-sub-container">
            <div className="scroll-card-company-name">{card?.companyName}</div>
            <div className="scroll-card-keyinfo-container">
             <div className="scroll-card-info-box">
              <div className="scroll-card-info-image">
               <img src={calender} alt="info" />
              </div>
              <div className="scroll-card-info-text">Since {card?.yearOfFoundation}</div>
             </div>
             <div className="seperator-image">
              <img src={seperator} alt="seperator" />
             </div>
             <div className="scroll-card-info-box">
              <div className="scroll-card-info-image">
               <img src={userthree} alt="info" />
              </div>
              <div className="scroll-card-info-text">Since {card?.employees}</div>
             </div>
            </div>
            <div className="scroll-card-info-box">
             <div className="verification-image">
              <img src={verification} alt="verification" />
             </div>
             <div className="scroll-card-verification-text">MANUALLY VERIFIED</div>
            </div>
           </div>
           <div className="company-details-sub-container">
            {card?.servicesFocus?.[0]?.services?.slice(0, 2)?.map((category, index) => (
             <div key={createKey(index, category?.name)} className="scroll-card-service-container">
              <div className="scroll-card-services">{category?.name}</div>
             </div>
            ))}
           </div>
           <div className="company-details-sub-container">
            <div className="scroll-card-previous-clients">Previous clients</div>
            <div className="scroll-card-clients-image-container">
             {clients?.map((image, index) => (
              <div key={createKey(index, image)} className="previous-clients-image">
               <img onError={onImageError} src={image} alt="logo" />
              </div>
             ))}
            </div>
           </div>
           <div className="scroll-card-profile-button">
            <div className="scroll-card-company-name">See full profile</div>
            <div className="scroll-card-arrow">
             <img src={arrow} alt="arrow" />
            </div>
           </div>
          </div>
         </div>
        </div>
       )
     )}
    </div>
   </div>
  </div>
 );
};
export default AgencyScroll;

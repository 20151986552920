const development = {
 create_user: '/user/create-user',
 login: '/login',
 forgot_password: '/forgot-password',
 reset_password: '/reset-password',
 get_agencyList: '/agency-list-details',
 get_bookmarkList: '/bookmark-lists',
 home: '/home',
 projects: '/projects',
 me: '/me',
 update_bookmarks: '/update-bookmarks',
 agency_details: '/agencies/path1',
 filter: '/filter',
 digital_agencies: '/digital-agencies',
 get_topAgency: '/top-agency'
};

export function getAllEndpoints() {
 return Object.keys(development).reduce(function (acc, item) {
  acc[item] = development[item];
  return acc;
 }, {});
}

export function getBaseURL() {
 return process.env.BASE_URL;
 // return "http://localhost:3001";
}

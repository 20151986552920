import React from 'react';

import RefProvider from 'Utilities/refProvider';
import RefErrorBoundary from 'Utilities/refErrorBoundary';
import { formStoreData } from 'Utilities/helpers';
import Agencies from './topAgency';

const TopAgency = (props) => {
 const propShape = formStoreData(props, ['dashboard','auth']);
 return (
  <>
   <RefProvider data={propShape}>
    <RefErrorBoundary {...props}>
     <Agencies />
    </RefErrorBoundary>
   </RefProvider>
  </>
 );
};

export default TopAgency;

import React, { useContext, useEffect, useState } from 'react';
import 'Styles/index.css';
import Logout from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import BasicModal from './modal';
import MenuComponent from './menu';
import Button from './button';
import urls from 'Config/url';
import RefContext from 'Utilities/refContext';
import Verification from '../ProjectBriefPage/verificationModal';
import ProjectDetailsForm from '../ProjectBriefPage/projectDetails';
import profile from 'Assets/profile.svg';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';

const SidebarRight = ({ Content }) => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { getUser } = actions;
 const { user } = store;
 const [projectDetailModalOpen, setProjectDetailModalOpen] = useState(false);
 const [openPopUp, setOpenPopUp] = useState(false);
 const [authType, setAuthType] = useState('signin');
 const [anchorEl, setAnchorEl] = useState(null);
 const checkUser = cookie.load('access_token');
 const [verificationModalOpen, setVerificationModalOpen] = useState(false);
 const listItems = [
  {
   name: checkUser ? 'Logout' : 'Login',
   icon: checkUser ? <Logout fontSize="small" /> : <LoginIcon fontSize="small" />
  }
 ];
 const navigate = useNavigate();

 const projectDetailsModalProps = {
  Content: ProjectDetailsForm,
  setOpen: setProjectDetailModalOpen,
  open: projectDetailModalOpen,
  setVerificationModalOpen,
  setOpenPopUp,
  setAuthType
 };
 const verificationModalProps = {
  Content: Verification,
  setOpen: setVerificationModalOpen,
  open: verificationModalOpen,
  setOpenPopUp,
  setAuthType
 };

 const [isClient, setIsClient] = useState(false);

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  setIsClient(true);
 }, []);

 useEffect(() => {
  if (!user && cookie.load('access_token')) {
   getUser();
  }
 }, [user]);

 if (!isClient) {
  return null;
 }

 //********************************************************Functions***************************************************//

 const handleMenuClose = () => {
  setAnchorEl(null);
 };

 const openMenuHandler = (event) => {
  setAnchorEl(event.currentTarget);
 };

 //Navigate to the login page if it is not logged in else the project detail modal opens
 const handleRoute = () => {
  if (checkUser) {
   setProjectDetailModalOpen(true);
  } else {
   navigate(urls?.URL_AUTH, { state: { isLoggin: false } });
  }
 };

 //Setting the broken image
 const onImageError = (e) => {
  e.target.src = profile;
 };

 //********************************************************html***************************************************//

 return (
  <div className={'rightside-section'}>
   <div className={'head-rightside-container'}>
    <div onClick={() => handleRoute()} className={'get-agency-button'}>
     <Button.Primary bgcolor="var(--primary-color)" content="Get an agency" />
    </div>

    <div className={'profile-image'} onClick={(event) => openMenuHandler(event)}>
     {user && user?.profile ? (
      <img onError={onImageError} src={user.profile} alt="profile" />
     ) : (
      <img alt="profile" src={profile} />
     )}
    </div>
   </div>
   <MenuComponent
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    handleClose={handleMenuClose}
    listItems={listItems}
    user={user}
   />
   {verificationModalOpen && <BasicModal {...verificationModalProps} />}
   {projectDetailModalOpen && <BasicModal {...projectDetailsModalProps} />}
   {Content}
  </div>
 );
};

export default SidebarRight;

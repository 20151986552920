import React from 'react';
import 'Styles/LandingPage/landing.css';
import logo from 'Assets/logo.svg';
import title from 'Assets/light-title.svg';
import Button from '../CommonComponents/button';
import { useNavigate } from 'react-router-dom';
import urls from 'Config/url';

const LandingHeader = () => {
 const navigate = useNavigate();

 //Navigate to the login page
 const handleRoute = () => {
  navigate(urls.URL_AUTH);
 };

 //********************************************************html***************************************************//

 return (
  <div className="landingpage-header-container">
   <div className="landing-header-container">
    <div className="landing-header-logo-container">
     <div className="landing-header-logo">
      <img src={logo} alt="tokai" width="100%" height="100%" />
     </div>
     <div className="landing-app-name">
      <img src={title} alt="tokai" width="100%" height="100%" />
     </div>
    </div>
    <div className="landing-navigation-tabs">
     <button className="landing-tabs" onClick={() => navigate(urls?.URL_HOME)}>
      Home
     </button>
     <button
      className="landing-tabs"
      onClick={() => {
       navigate(urls?.URL_TOPAGENCY), localStorage?.setItem('tab', 'topAgency');
      }}>
      Top agencies
     </button>
     <button disabled={true} className="landing-tabs-forum" style={{ color: '#FFFFFF66' }}>
      Forum
     </button>
     <button className="landing-tabs">Contact us</button>
    </div>
    <div onClick={() => handleRoute()} className={'hire-button'}>
     <Button.Primary hoverType="hover3" content="Start Hiring" />
    </div>
   </div>
  </div>
 );
};
export default LandingHeader;

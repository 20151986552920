import React from "react";
import RefContext from "Utilities/refContext";

const RefProvider = (props) => {
  const { data, children } = props;

  const Context = RefContext;

  return <Context.Provider value={data}>{children}</Context.Provider>;
};

export default RefProvider;

import React, { useEffect } from 'react';
import 'Styles/LandingPage/landing.css';
import LandingHiringByDuration from 'Components/UI/LandingPage/landingHiringByDuration';
import LandingTop1PercentAgencies from 'Components/UI/LandingPage/landingTop1PercentAgencies';
import LandingHireByExpertiseAndSkills from 'Components/UI/LandingPage/landingHireByExpertiseAndSkills';
import LandingHireNextProjectSimple from 'Components/UI/LandingPage/landingHireNextProjectSimple';
import LandingHeader from 'Components/UI/LandingPage/landingHeader';
import LandingTopTier from 'Components/UI/LandingPage/landingToptier';
import LanidngFooter from 'Components/UI/LandingPage/landingFooter';
import AgencyScroll from '../../components/UI/LandingPage/landingAgencyScroll';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import urls from 'Config/url';
import { Helmet } from 'react-helmet';

const Landingpage = () => {
 const navigate = useNavigate();

 useEffect(() => {
  if (cookie.load('access_token')) {
   navigate(urls?.URL_HOME);
  }
 }, []);

 //********************************************************html***************************************************//

 return (
  <div className="landingpage-container">
   <Helmet>
    <title>{`Tokai`}</title>
    <meta name="robots" content="noindex,nofollow" />
    <meta property="og:site_name" content={'Tokai'} />
   </Helmet>
   <div className="landingpage-wrapper">
    <LandingHeader />
    <div className="landingpage-components-container">
     <LandingTopTier />
     <AgencyScroll />
     <LandingHiringByDuration />
     <LandingTop1PercentAgencies />
     <LandingHireByExpertiseAndSkills />
     <LandingHireNextProjectSimple />
    </div>
    <LanidngFooter />
   </div>
  </div>
 );
};

export default Landingpage;

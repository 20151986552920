import React from 'react';
import { useState, useContext } from 'react';
import RefContext from 'Utilities/refContext';
import 'Styles/forgotPassword.css';
import rightArrowImg from 'Assets/arrowRight.svg';
import { checkValidMail } from 'Utilities/helpers';
import Button from '../CommonComponents/button';

const ForgotPassword = ({ setAuthType, setOpen }) => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { actions } = ctx;
 const { forgotPassword } = actions;
 const [email, setEmail] = useState('');
 const [error, setError] = useState('');
 const [resetPassword, setResetPassword] = useState(false);

 //********************************************************Functions***************************************************//

 const routeHandler = () => {
  setOpen(false);
  setAuthType('signin');
 };

 // Field validation
 const handleChange = () => {
  if (email === '') {
   setError('Required email');
  } else if (!checkValidMail(email)) {
   setError('Invalid email');
  } else {
   forgotPassword({ email: email }, setResetPassword, setError);
  }
 };

 //********************************************************html***************************************************//

 return (
  <div className={'forgot-password-container forgot-password-wrapper'}>
   {resetPassword ? (
    <>
     <link
      href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
      rel="stylesheet"
     />
     <section class="mail-seccess">
      <div class="success-inner">
       <h1>
        <i class="fa fa-envelope"></i>
       </h1>
      </div>
     </section>
     <div className="email-success-message">Mail sent successfully!</div>
     <div className="email-success-description">
      <span>
       A password reset link has been sent to your email. Please check your inbox and spam folder,
       just in case!
      </span>
      <div className="login mail-success-login" onClick={() => routeHandler()}>
       Log In
      </div>
     </div>
    </>
   ) : (
    <>
     <div className={'forgot-password-content'}>
      <div className={'title forgot-password-title'}>Forgot Password?</div>
      <div className={'description'}>
       We will send an email with verification code. If you don’t see it, please check your spam
       folder.
      </div>
     </div>
     <div className="forgot-password-container">
      <div className="email-content">
       <div className="email-field">
        <div className="field-name">Email</div>
        <div className="field">
         <input
          value={email}
          type="text"
          maxLength={50}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="you@yourdomain.com"
          className="forgot-password-input-field"></input>
        </div>
        <div style={{ height: '24px', fontSize: 'x-small', color: 'red' }}>{error || ''}</div>
       </div>
       <div onClick={handleChange}>
        <Button.Primary
         bgcolor="var(--primary-color)"
         content={
          <div className="auth-button-container">
           <div className="auth-btn-title">Send reset instructions</div>
           <img alt="arrow" src={rightArrowImg} />
          </div>
         }
        />
       </div>
      </div>
      <div className="login-option">
       <div className="login-content">Remember password?</div>
       <div className="login" onClick={() => routeHandler()}>
        Log In
       </div>
      </div>
     </div>
    </>
   )}
  </div>
 );
};

export default ForgotPassword;

import React, { useContext, useEffect, useRef, useState } from 'react';
import 'Styles/responsiveHeader.css';
import RefContext from 'Utilities/refContext';
import { createKey } from 'Utilities/helpers';
import Button from './button';
import logo from 'Assets/logo.svg';
import title from 'Assets/title.svg';
import searchBar from 'Assets/search.svg';
import urls from 'Config/url';
import cookie from 'react-cookies';
import BasicModal from './modal';
import { useLocation, useNavigate } from 'react-router-dom';
import Verification from '../ProjectBriefPage/verificationModal';
import ProjectDetailsForm from '../ProjectBriefPage/projectDetails';

const ResponsiveHeader = () => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store } = ctx;
 const { topAgency, modalopen } = store;
 const user = cookie.load('access_token');
 const [opensearch, setOpensearch] = useState(false);
 const [searchInput, setSearchInput] = useState('');
 const [searchItem, setSearchItem] = useState([]);
 const navigate = useNavigate();
 const location = useLocation();
 const [projectDetailModalOpen, setProjectDetailModalOpen] = useState(false);
 const [openPopUp, setOpenPopUp] = useState(false);
 const [authType, setAuthType] = useState('signin');
 const [verificationModalOpen, setVerificationModalOpen] = useState(false);
 const searchbarInput = useRef(null);

 const projectDetailsModalProps = {
  Content: ProjectDetailsForm,
  setOpen: setProjectDetailModalOpen,
  open: projectDetailModalOpen,
  setVerificationModalOpen,
  setOpenPopUp,
  setAuthType
 };
 const verificationModalProps = {
  Content: Verification,
  setOpen: setVerificationModalOpen,
  open: verificationModalOpen,
  setOpenPopUp,
  setAuthType
 };

 //********************************************************UseEffect***************************************************//

 //Filters the data according to the search input
 useEffect(() => {
  const filteredCategories = topAgency?.topAgencies_category?.filter((category) =>
   category?.title?.toLowerCase().includes(searchInput?.toLowerCase())
  );
  setSearchItem(filteredCategories);
 }, [searchInput]);

 // Close the dropdown when clicking outside
 useEffect(() => {
  const handleOutSideClick = (event) => {
   if (!searchbarInput.current?.contains(event.target)) {
    setSearchInput('');
    setOpensearch(false);
   }
  };
  window.addEventListener('mousedown', handleOutSideClick);

  return () => {
   window.removeEventListener('mousedown', handleOutSideClick);
  };
 }, []);

 //********************************************************Functions***************************************************//

 //Navigate to the login page if it is not logged in else navigate tot the peoject detail page
 const handleRoute = () => {
  if (user) {
   setProjectDetailModalOpen(true);
  } else {
   navigate(urls?.URL_AUTH);
  }
 };

 //Navigate to the selected category page
 const handleCategory = (category) => {
  setSearchInput('');
  navigate(`/${category?.slug}`, {
   state: { category: category?.category, title: category?.title }
  });
  setOpensearch(!opensearch);
  window?.location?.reload();
 };

 //Stores the onchange search input value
 const handleSearch = (event) => {
  setSearchInput(event.target.value);
 };

 //Navigate to the landing page if it is not logged in else navigate to the home page
 const authHandle = () => {
  if (user) {
   navigate(urls?.URL_HOME);
  } else {
   navigate(urls?.URL_LANDING);
  }
 };

 //********************************************************html***************************************************//

 return (
  <div
   className={'responsive-header-container'}
   style={{ padding: opensearch ? '21px 20px' : '', background: opensearch ? 'white' : '' }}>
   <div ref={searchbarInput} className="responsive-header-content">
    {!opensearch && (
     <>
      <div onClick={() => authHandle()} className={'responsive-logo-content'}>
       <div className={'responsive-logo'}>
        <img alt="rect" src={logo} width="100%" height="100%" />
       </div>
       <div className={'responsive-title'}>
        <img alt="rect" src={title} width="49px" height="29px" />
       </div>
      </div>
      {location?.pathname !== '/login' &&
       location?.pathname !== '/project-detail' &&
       !modalopen && (
        <div className={'responsive-header-right'}>
         <div className={'responsive-search-bar'}>
          <div
           className={'responsive-search-bar-container'}
           onClick={() => setOpensearch(!opensearch)}>
           <img alt="rect" src={searchBar} width="100%" height="100%" />
          </div>
         </div>
         <div onClick={() => handleRoute()} className={'responsive-button-container'}>
          <Button.Primary bgcolor="var(--primary-color)" content="Get an agency" />
         </div>
        </div>
       )}
     </>
    )}
    {opensearch && (
     <div style={{ width: '100%' }} className={'responsive-header-right'}>
      <div className={'search-bar-image'}>
       <img alt="rect" src={searchBar} width="100%" height="100%" />
      </div>
      <input
       className={'searchBarInput'}
       placeholder="Search by software service type. . ."
       value={searchInput}
       onChange={(event) => handleSearch(event)}
      />
     </div>
    )}

    {searchInput?.length > 0 && (
     <div className={'dropdown-container'}>
      <div className={'dropdown-content'}>
       {searchItem?.map((value, index) => (
        <div
         key={createKey(value?.title, index)}
         onClick={() => handleCategory(value)}
         className={'dropdown-value'}>
         {value?.title}
        </div>
       ))}
       {searchItem?.length === 0 && <div className="searchBarInput">No data found</div>}
      </div>
     </div>
    )}
   </div>
   {verificationModalOpen && <BasicModal {...verificationModalProps} />}
   {projectDetailModalOpen && <BasicModal {...projectDetailsModalProps} />}
  </div>
 );
};
export default ResponsiveHeader;

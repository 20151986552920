import React from 'react';
import 'Styles/LandingPage/landing.css';
import Button from '../CommonComponents/button';
import { createKey } from 'Utilities/helpers';
import { useNavigate } from 'react-router-dom';
import urls from 'Config/url';

const LandingTopTier = () => {
 //********************************************************Constants***************************************************//

 const navigate = useNavigate();
 const info = [
  {
   number: '1,500+',
   content: 'Handpicked, Vetted agencies'
  },
  {
   number: '48 Hours',
   content: 'Average match time'
  },
  {
   number: '4m+ Hours',
   content: 'Worked on projects globally'
  }
 ];

 //********************************************************Function***************************************************//

 //Navigate to login page
 const handleRoute = () => {
  navigate(urls.URL_AUTH);
 };

 //********************************************************html***************************************************//

 return (
  <div className="landingpage-toptier-container">
   <div className="toptier-title-container">
    <div className="toptier-title-wrapper">
     <div className="toptier-title1">Find</div>
     <div className="toptier-title2">top - tier</div>
     <div className="toptier-title3">development agencies.</div>
    </div>
    <div className="toptier-content">
     We source, vet, and match you with top agencies to meet your business needs, ensuring fast
     project delivery and quality execution, risk-free.
    </div>
   </div>
   <div className="toptier-button-container">
    <div onClick={() => navigate(urls.URL_HOME)} className={'toptier-buttons'}>
     <Button.Primary content="Explore Agencies" textColor="#FFFFFF" hoverType="hover2" />
    </div>
    <div onClick={() => handleRoute()} className={'toptier-buttons'}>
     <Button.Primary hoverType="hover1" content="Start Hiring" />
    </div>
   </div>
   <div className="toptier-info-container">
    {info?.map((data, index) => (
     <div
      key={createKey(index, info?.content)}
      style={{ borderRight: index === info?.length - 1 ? '2px solid #347928' : '' }}
      className="toptier-info-box">
      <div className="toptier-info-content1">{data?.number}</div>
      <div className="toptier-info-content2">{data?.content}</div>
     </div>
    ))}
   </div>
  </div>
 );
};
export default LandingTopTier;

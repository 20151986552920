import React, { useEffect } from 'react';
import { useState } from 'react';
import 'Styles/responsiveFilter.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '../CommonComponents/button';
import { createKey } from 'Utilities/helpers';
import filter from 'Assets/filters.svg';
import close from 'Assets/close.svg';
import tick from 'Assets/tick.svg';
import search from 'Assets/search.svg';

const FilterModal = ({
 open,
 setOpen,
 dropdownMenus,
 setPageNumber,
 setFilterOptions,
 selectedOptions,
 setSelectedOptions,
 filterOption
}) => {
 //********************************************************Constants***************************************************//

 const [selectedFilter, setSelectedFilter] = useState('Category');
 const [searchInput, setSearchInput] = useState('');

 const style = {
  position: 'absolute',
  top: '66%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: '#FFFFFF',
  height: '100%',
  borderRadius: '12px 12px 0px 0px',
  boxShadow: '0px 3px 6px 0px #091E4208',
  outline: 'none'
 };
 const formData = [
  {
   title: 'Category',
   options: dropdownMenus?.data?.attributes?.Category?.split(',')?.map((category) => {
    return { name: category };
   })
  },
  {
   title: 'ClientFocus',
   options: dropdownMenus?.data?.attributes?.ClientFocus.split(',')?.map((clientFocus) => {
    return { name: clientFocus };
   })
  },
  {
   title: 'CompanySize',
   options: dropdownMenus?.data?.attributes?.CompanySize.split(',')?.map((companySize) => {
    return { name: companySize };
   })
  },
  {
   title: 'Domain',
   options: dropdownMenus?.data?.attributes?.Domain.split(',')?.map((domain) => {
    return { name: domain };
   })
  },
  {
   title: 'Frameworks',
   options: dropdownMenus?.data?.attributes?.Frameworks.split(',')?.map((frameworks) => {
    return { name: frameworks };
   })
  },
  {
   title: 'HourlyRate',
   options: dropdownMenus?.data?.attributes?.HourlyRate.split(',')?.map((hourlyRate) => {
    return { name: hourlyRate };
   })
  },
  {
   title: 'Location',
   options: dropdownMenus?.data?.attributes?.Location.split(',')?.map((location) => {
    return { name: location };
   })
  },
  {
   title: 'Language',
   options: dropdownMenus?.data?.attributes?.Language.split(',')?.map((language) => {
    return { name: language };
   })
  },
  {
   title: 'MinProjectSize',
   options: dropdownMenus?.data?.attributes?.MinProjectSize?.slice(1, -1)
    ?.split("','")
    ?.map((minProjectSize) => {
     return { name: minProjectSize };
    })
  }
 ];

 useEffect(() => {
  if (filterOption) {
   setSelectedOptions(filterOption);
  }
 }, [filterOption]);

 //********************************************************Functions***************************************************//

 //Close the filter modal
 const handleClose = (event) => {
  event.stopPropagation();
  setOpen(false);
 };

 const handleModalClick = (event) => {
  event.stopPropagation();
 };

 //shows the selected category options
 const handleFilterSelect = (title) => {
  setSelectedFilter(selectedFilter === title ? selectedFilter : title);
 };

 //Stores the selected filter value
 const handleCheckboxToggle = (filterTitle, optionName) => {
  setSelectedOptions((prev) => {
   const currentOptions = prev[filterTitle] || [];
   if (currentOptions.includes(optionName)) {
    return {
     ...prev,
     [filterTitle]: currentOptions.filter((name) => name !== optionName)
    };
   } else {
    return {
     ...prev,
     [filterTitle]: [...currentOptions, optionName]
    };
   }
  });
 };

 //Applying filters to the api
 const handleAgencies = async () => {
  if (
   (Object.keys(selectedOptions)?.length > 0 &&
    Object.values(selectedOptions).some((value) => value?.length > 0)) ||
   Object.values(filterOption).some((value) => value?.length > 0)
  ) {
   setFilterOptions(selectedOptions);
   setPageNumber(1);
   setOpen(false);
  }
 };

 //Clearing the filter option
 const handleClear = async () => {
  setFilterOptions({});
  setSelectedOptions({});
 };

 //Disable the filters apply button if no filters is selected
 const disableApplybutton = () => {
  const isFiltersEmpty =
   selectedOptions && Object.values(selectedOptions).every((options) => options?.length === 0);

  return isFiltersEmpty;
 };

 // Handle search input change
 const handleSearch = (event) => {
  setSearchInput(event.target.value);
 };

 //********************************************************html***************************************************//

 return (
  <Modal
   open={open}
   onClose={handleClose}
   aria-labelledby="modal-modal-title"
   aria-describedby="modal-modal-description">
   <Box sx={style}>
    <div style={{ gap: '0px' }} className={'modal-filter'} onClick={handleModalClick}>
     <div className={'filter-container'}>
      <div className={'filter-head'}>
       <div className={'filter-icon-container'}>
        <div className={'filter-icon-image'}>
         <img alt="filter" width="100%" height="100%" src={filter} />
        </div>
        <div className={'filter-content'}>Filter</div>
       </div>
       <div className={'close'}>
        <div onClick={() => setOpen(false)} className={'close-icon'}>
         <img alt="close" src={close} width="100%" height="100%" />
        </div>
       </div>
      </div>
     </div>
     <div className={'filter-main-container'}>
      <div className={'filter-main-content'}>
       {formData?.map((data, index) => (
        <div
         key={createKey(data?.title, index)}
         className={'filter-category-box'}
         style={{
          borderLeft: data.title === selectedFilter ? '3px solid #0091FF' : '3px solid transparent'
         }}
         onClick={() => handleFilterSelect(data.title)}>
         {data.title}
         <div className={'selected-number'}>{selectedOptions[data.title]?.length || ''}</div>
        </div>
       ))}
      </div>
      {selectedFilter &&
       formData
        .filter((data) => data.title === selectedFilter)
        ?.map((data) => (
         <div key={data.title} className={'filter-checkbox-content'}>
          {data?.options?.length > 6 && (
           <div className="responsive-filter-search-option">
            <div className="responsive-filtersearch-box-container">
             <div className="responsive-searchImageWrapper">
              <img className="searchImg" alt="lens" src={search} width="100%" height="100%" />
             </div>
             <input
              className="searchBarInput"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearch}
             />
            </div>
           </div>
          )}
          {data.options
           ?.filter((option) => option.name.toLowerCase().includes(searchInput.toLowerCase())) // Filter the options by the search input
           .map((option, i) => (
            <div
             key={createKey(option.name, i)}
             className={'checkbox-container'}
             onClick={() => handleCheckboxToggle(data.title, option.name)}>
             <div
              className={'check-box'}
              style={{
               border: selectedOptions[data.title]?.includes(option.name)
                ? '1px solid #0091FF'
                : '1px solid #ccc',
               background: selectedOptions[data.title]?.includes(option.name)
                ? '#DAF4FF'
                : 'transparent'
              }}>
              {selectedOptions[data.title]?.includes(option.name) && (
               <div className={'tick-image'}>
                <img alt="tick" width="100%" height="100%" src={tick} />
               </div>
              )}
             </div>
             <div className={'option-label'}>{option.name}</div>
            </div>
           ))}
         </div>
        ))}
     </div>
     <div className={'filter-button-container'}>
      <div onClick={() => handleClear()} style={{ width: '50%' }}>
       <Button.Secondary width="100%" content="Clear all" size={'medium'} />
      </div>
      <div style={{ width: '50%' }} onClick={() => handleAgencies()}>
       <Button.Common disable={disableApplybutton()} width="100%" content="Apply" size={'medium'} />
      </div>
     </div>
    </div>
   </Box>
  </Modal>
 );
};

export default FilterModal;

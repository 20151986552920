import cloneDeep from 'lodash/cloneDeep';
import { setNamespace, setApiError } from 'Utilities/helpers';
import Network from 'Utilities/network';
import cookie from 'react-cookies';
import { Notification } from '@contentful/f36-components';
import authDetails from '../config/authHeaders';

const namespace = 'auth';
const createAction = setNamespace(namespace);
const nw = new Network();

// STORE
const initialState = {
 apiError: null,
 user: null,
 password_details: null
};

// ACTIONS
const ASSIGN_TO_AUTH_STORE = createAction('ASSIGN_TO_AUTH_STORE');
const RESET_AUTH_STORE = createAction('RESET_AUTH_STORE');

const assignToAuthStore = (type, payload) => ({
 type: ASSIGN_TO_AUTH_STORE,
 meta: {
  type,
  payload
 }
});

const resetAuthStore = () => (dispatch) => {
 dispatch({
  type: RESET_AUTH_STORE,
  meta: {
   payload: null
  }
 });
};

// METHODS
const login = (request, navigate, location) => (dispatch) => {
 return nw
  .api('login')
  .post(request)
  .then((resp) => {
   const { token } = resp.data.user;
   cookie.save('access_token', token);
   authDetails['authorization'] = token;
   dispatch(assignToAuthStore('user', resp.data.user));
   Notification.setPlacement('top');
   Notification.success('Logged in successfully');
   navigate(
    location && location?.state?.isLoggin
     ? window.history.length > 1
       ? window.history.back()
       : '/home'
     : '/project-detail'
   );
  })
  .catch((error) => {
   Notification.setPlacement('top');
   Notification.error(error?.response?.data?.error?.message);
  });
};

const logout = () => (dispatch) => {
 authDetails['authorization'] = null;
 dispatch(assignToAuthStore('user', null));
};

const forgotPassword = (request, setResetPassword, setError) => {
 return nw
  .api('forgot_password')
  .post(request)
  .then((resp) => {
   setResetPassword(true);
   setError('');
  })
  .catch((error) => {
   setError(error?.response?.data?.error?.message);
  });
};

const resetPassword = (request, setNewPassword, newPassword, setIsSuccess) => {
 return nw
  .api('reset_password')
  .post(request)
  .then((resp) => {
   setNewPassword({
    ...newPassword,
    password_1: '',
    password_2: ''
   });
   setIsSuccess(true);
   Notification.setPlacement('top');
   Notification.success('Password updated successfully');
  })
  .catch((error) => {
   Notification.setPlacement('top');
   Notification.error(error?.response?.data?.error?.message);
  });
};

const getUser = () => (dispatch) => {
 dispatch(assignToAuthStore('user', null));

 return nw
  .api('me')
  .get()
  .then((response) => {
   dispatch(assignToAuthStore('user', response?.data));
  })
  .catch((error) => {
   setApiError(dispatch, assignToAuthStore, error);
  });
};

// Routing

// Reducers
const authReducer = (state = initialState, action) => {
 const localState = cloneDeep(state);

 switch (action.type) {
  case ASSIGN_TO_AUTH_STORE:
   localState[action?.meta?.type] = action?.meta?.payload;
   return { ...localState };
  case RESET_AUTH_STORE:
   return initialState;
  default:
   return localState;
 }
};

export default {
 namespace,
 store: initialState,
 reducer: authReducer,
 creators: {
  assignToAuthStore,
  resetAuthStore,
  login,
  logout,
  getUser,
  forgotPassword,
  resetPassword
 }
};

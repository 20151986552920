import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { createKey } from 'Utilities/helpers';
import { useNavigate, useLocation } from 'react-router-dom';

const BreadCrumbs = ({ breadcrumbs }) => {
 //********************************************************Constants***************************************************//

 let location = useLocation();
 const navigate = useNavigate();

 //********************************************************Functions***************************************************//

 //Redirect back to the pages
 const handleRoute = (link) => {
  navigate(link, {
   state: location?.state?.name
  });
 };

 //********************************************************html***************************************************//

 return (
  <Stack>
   <Breadcrumbs separator={<NavigateNextIcon fontSize="14px" />} aria-label="breadcrumb">
    {breadcrumbs?.map((breadcrumb, index) => (
     <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      key={createKey(index, breadcrumb.label)}>
      {breadcrumb?.url ? (
       <Link
        fontSize="14px"
        underline="hover"
        color="#333333"
        onClick={() => handleRoute(breadcrumb.url)}>
        {breadcrumb.label}
       </Link>
      ) : (
       <Typography className='breadcrumb' sx={{ color: '#333333', fontSize: '14px', fontWeight: '600' }}>
        {breadcrumb.label}
       </Typography>
      )}
     </div>
    ))}
   </Breadcrumbs>
  </Stack>
 );
};

export default BreadCrumbs;

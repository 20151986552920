import React from 'react';
import 'Styles/categoryTypes.css';
import code from 'Assets/Categories/codeimage.svg';
import { createKey } from '../../../utilities/helpers';
import servicelines from 'Assets/Categories/servicelines.svg';
import domainfocus from 'Assets/Categories/domainfocus.svg';
import cms from 'Assets/Categories/cmssolution.svg';
import program from 'Assets/Categories/DeviceMobile.svg';
import framework from 'Assets/Categories/framework.svg';
import software from 'Assets/Categories/customsoftware.svg';
import ai from 'Assets/Categories/ai.svg';
import mobile from 'Assets/Categories/Cube.svg';
import qa from 'Assets/Categories/Note.svg';
import itconsultancy from 'Assets/Categories/itconsulting.svg';
import design from 'Assets/Categories/PaintBrush.svg';
import ecommerce from 'Assets/Categories/Storefront.svg';
import devops from 'Assets/Categories/UsersFour.svg';
import game from 'Assets/Categories/gamedevelopment.svg';

import activecode from 'Assets/Categories/activeCodeimage.svg';
import activeservicelines from 'Assets/Categories/activeServicelines.svg';
import activedomainfocus from 'Assets/Categories/activeDomainfocus.svg';
import activecms from 'Assets/Categories/activeCmssolution.svg';
import activeprogram from 'Assets/Categories/activeDeviceMobile.svg';
import activeframework from 'Assets/Categories/activeFramework.svg';
import activesoftware from 'Assets/Categories/activeCustomsoftware.svg';
import activeai from 'Assets/Categories/activeAi.svg';
import activemobile from 'Assets/Categories/activeCube.svg';
import activeqa from 'Assets/Categories/activeNote.svg';
import activeitconsultancy from 'Assets/Categories/activeItconsulting.svg';
import activedesign from 'Assets/Categories/activePaintBrush.svg';
import activeecommerce from 'Assets/Categories/activeStorefront.svg';
import activedevops from 'Assets/Categories/activeUsersFour.svg';
import activegame from 'Assets/Categories/activeGamedevelopment.svg';

const CategoryTypes = ({ topAgency, activeCategory, setActiveCategory }) => {
 //********************************************************Constants***************************************************//

 const categoryImages = [
  {
   image: servicelines,
   active: activeservicelines
  },
  {
   image: domainfocus,
   active: activedomainfocus
  },
  {
   image: cms,
   active: activecms
  },
  {
   image: code,
   active: activecode
  },
  {
   image: framework,
   active: activeframework
  },
  {
   image: software,
   active: activesoftware
  },
  {
   image: ecommerce,
   active: activeecommerce
  },
  {
   image: program,
   active: activeprogram
  },
  {
   image: itconsultancy,
   active: activeitconsultancy
  },
  {
   image: design,
   active: activedesign
  },
  {
   image: ai,
   active: activeai
  },
  {
   image: mobile,
   active: activemobile
  },
  {
   image: devops,
   active: activedevops
  },
  {
   image: game,
   active: activegame
  },
  {
   image: qa,
   active: activeqa
  },
  {
   image: mobile,
   active: activemobile
  },
  {
   image: ecommerce,
   active: activeecommerce
  }
 ];

 //********************************************************html***************************************************//

 return (
  <div className={'types-container'}>
   {topAgency?.topAgencies_category?.map((categorytype, index) => (
    <a
     href={`#${categorytype?.category}`}
     onClick={() => setActiveCategory(categorytype?.title)}
     style={{
      borderLeft:
       activeCategory === categorytype?.title ? '3px solid #0091FF' : '3px solid transparent'
     }}
     key={createKey(categorytype.title, index)}
     className={'category-type-container'}>
     <div className={'category-image'}>
      <img
       alt="code"
       src={
        activeCategory === categorytype?.title
         ? categoryImages[index]?.active
         : categoryImages[index]?.image
       }
       width="100%"
       height="100%"
      />
     </div>
     <div className={'category-type-name'}>{categorytype?.category}</div>
    </a>
   ))}
  </div>
 );
};
export default CategoryTypes;

import React from 'react';
import { useEffect, useRef, useState } from 'react';
import 'Styles/agencyForm.css';
import { createKey } from 'Utilities/helpers';
import filter from 'Assets/filter.svg';
import tick from 'Assets/tick.svg';
import search from 'Assets/search.svg';
import arrow from 'Assets/downArrow.svg';
import Button from '../CommonComponents/button';
import { useLocation } from 'react-router-dom';

const AgencyForm = ({ dropdownMenus, setFilterOptions, setPageNumber, filterOption }) => {
 //********************************************************Constants***************************************************//

 const dropdown = useRef(null);
 const [openIndex, setOpenIndex] = useState(null);
 const [searchTerm, setSearchTerm] = useState('');
 const [selectedIndex, setSelectedIndex] = useState(-1);
 const [filters, setFilters] = useState({});
 const [isFirstClear, setIsFirstClear] = useState(true);

 let location = useLocation();

 const formData = [
  {
   title: 'ServiceLine',
   options: dropdownMenus?.data?.attributes?.ServiceLine?.map((serviceLine) => {
    return {
     category: serviceLine?.category,
     services: serviceLine?.services?.map((service) => ({
      name: service?.name,
      slug: service?.slug
     }))
    };
   })
  },
  {
   title: 'ClientFocus',
   options: dropdownMenus?.data?.attributes?.ClientFocus.split(',')?.map((clientFocus) => {
    return { name: clientFocus };
   })
  },
  {
   title: 'CompanySize',
   options: dropdownMenus?.data?.attributes?.CompanySize.split(',')?.map((companySize) => {
    return { name: companySize };
   })
  },
  {
   title: 'Domain',
   options: dropdownMenus?.data?.attributes?.Domain.split(',')?.map((domain) => {
    return { name: domain };
   })
  },
  {
   title: 'Frameworks',
   options: dropdownMenus?.data?.attributes?.Frameworks.split(',')?.map((frameworks) => {
    return { name: frameworks };
   })
  },
  {
   title: 'HourlyRate',
   options: dropdownMenus?.data?.attributes?.HourlyRate.split(',')?.map((hourlyRate) => {
    return { name: hourlyRate };
   })
  },
  {
   title: 'Location',
   options: dropdownMenus?.data?.attributes?.Location.split(',')?.map((location) => {
    return { name: location };
   })
  },
  {
   title: 'Language',
   options: dropdownMenus?.data?.attributes?.Language.split(',')?.map((language) => {
    return { name: language };
   })
  },
  {
   title: 'MinProjectSize',
   options: dropdownMenus?.data?.attributes?.MinProjectSize?.slice(1, -1)
    ?.split("','")
    ?.map((minProjectSize) => {
     return { name: minProjectSize };
    })
  }
 ];

 //********************************************************UseEffects***************************************************//

 useEffect(() => {
  if (filterOption) {
   setFilters(filterOption);
  }
 }, [filterOption]);

 //Close the dropdown while clicking outside the dropdown container or while clicking the escape key
 useEffect(() => {
  const handleOutSideClick = (event) => {
   if (!dropdown.current?.contains(event.target)) {
    setOpenIndex(null);
   }
  };

  const handleEscKeyPress = (event) => {
   if (event.key === 'Escape') {
    setOpenIndex(null);
   }
  };

  window.addEventListener('mousedown', handleOutSideClick);
  window.addEventListener('keydown', handleEscKeyPress);

  return () => {
   window.removeEventListener('mousedown', handleOutSideClick);
   window.removeEventListener('keydown', handleEscKeyPress);
  };
 }, []);

 //********************************************************Functions***************************************************//

 // Opens the dropdown of the specific category field
 const handleClick = (index) => {
  setOpenIndex(openIndex === index ? null : index);
  setSelectedIndex(-1);
  setSearchTerm('');
 };

 // Store the search input in state
 const handleSearchChange = (event) => {
  setSearchTerm(event.target.value);
 };

 // Removing all filters and displaying the whole list
 const handleClear = () => {
  if (location?.state?.filters && isFirstClear) {
   setFilterOptions(location?.state?.filters);
   setIsFirstClear(false);
  }

  if (
   (JSON.stringify(filters) !== '{}' &&
    Object.values(filters).some((value) => value?.length > 0)) ||
   Object.values(filterOption).some((value) => value?.length > 0)
  ) {
   setFilters({});
   setFilterOptions({});
   setPageNumber(1);
   window.history.replaceState({}, '');
  }
 };

 //Make the checkbox toggle when it is clicked
 const handleCheckboxToggle = (filterTitle, optionName) => {
  setFilters((prev) => {
   const currentOptions = prev[filterTitle] || [];
   if (currentOptions.includes(optionName)) {
    return {
     ...prev,
     [filterTitle]: currentOptions.filter((name) => name !== optionName)
    };
   } else {
    return {
     ...prev,
     [filterTitle]: [...currentOptions, optionName]
    };
   }
  });
 };

 // Applying filters to the agency list
 const handleAgencies = async () => {
  if (location?.state?.filters && isFirstClear) {
   setFilterOptions(location?.state?.filters);
   setIsFirstClear(false);
  }

  if (
   (Object.keys(filters)?.length > 0 &&
    Object.values(filters).some((value) => value?.length > 0)) ||
   Object.values(filterOption).some((value) => value?.length > 0)
  ) {
   setFilterOptions(filters);
   setPageNumber(1);
   window.history.replaceState({}, '');
  }
 };

 //Disable the filters apply button if no filters is selected
 const disableApplybutton = () => {
  const isFiltersEmpty =
   filters && Object.values(filters).every((options) => options?.length === 0);
  const isFilterOptionEmpty = filterOption
   ? Object.values(filterOption).every((data) => data?.length === 0)
   : 'true';

  return isFiltersEmpty && isFilterOptionEmpty;
 };

 const count = (filterName) => filters[filterName]?.length || 0;

 //********************************************************html***************************************************//

 return (
  <div className={'agency-form-layout'}>
   <div className={'agency-form-container'}>
    <div className={'button-container'}>
     <div className={'button-left'}>
      <div className={'filter-image'}>
       <img alt="filter" src={filter} width="100%" height="100%" />
      </div>
      <div className={'filter-name'}>Filters</div>
     </div>
     <div className={'button-right'}>
      <button className={'clear-button'} onClick={() => handleClear()}>
       Clear all
      </button>
      <div onClick={() => handleAgencies()}>
       <Button.Common
        disable={disableApplybutton()}
        content="Apply"
        width={'72px'}
        size={'medium'}
       />
      </div>
     </div>
    </div>
    <div ref={dropdown} className={'agency-form-content'}>
     {(formData || [])?.map((category, index) => {
      const filteredOptions = category?.options?.filter((item) =>
       item.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      );

      return (
       <div key={createKey(index, category.title)} className={'agency-form-field'}>
        <button className={'listpage-field-name-container'} onClick={() => handleClick(index)}>
         <div className={'form-filter-field-name'}>
          {category?.title}
          {count(category?.title) !== 0 && (
           <span
            style={{ paddingLeft: '10px' }}
            className={'selected-number'}>{`( ${count(category?.title)} )`}</span>
          )}
         </div>
         <div
          className={'arrow-icon'}
          style={{
           transform: openIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
           transition: 'transform 0.3s ease'
          }}>
          <img alt="arrow" src={arrow} width={20} height={20} />
         </div>
        </button>
        <div className={'filters-dropdown-field'}>
         {openIndex === index && (
          <div className={'filters-dropdown-container'}>
           <div className={'filters-dropdown-content'}>
            {category?.options?.length > 10 && (
             <div className={'search-field-container'}>
              <div className={'search-field'}>
               <input
                placeholder="Search"
                className={'input-field'}
                value={searchTerm}
                onChange={handleSearchChange}
               />
               <div className={'arrow-icon'}>
                <img alt="search" src={search} width={20} height={20} />
               </div>
              </div>
             </div>
            )}
            <div className={'dropdown-list'}>
             {category?.title === 'ServiceLine' ? (
              <div key={createKey(index, category.title)}>
               {category?.options?.map((serviceCategory, i) => {
                const filteredServices = serviceCategory.services.filter((service) =>
                 service.name.toLowerCase().includes(searchTerm.toLowerCase())
                );

                const categoryMatch = serviceCategory.category
                 .toLowerCase()
                 .includes(searchTerm.toLowerCase());

                if (categoryMatch || filteredServices?.length > 0) {
                 return (
                  <div key={createKey(i, serviceCategory.category)}>
                   <div className="service-line-category">
                    <div className="category-name">{serviceCategory.category}</div>
                    <div className="services-list">
                     {serviceCategory.services?.map((service, j) => (
                      <div
                       key={createKey(j, service.name)}
                       className={`${'dropdown-field-container'} ${
                        filters[category.title]?.includes(service.name) ? 'selected' : ''
                       }`}>
                       <button
                        onClick={() => handleCheckboxToggle(category.title, service.name)}
                        className={'option-field'}>
                        <div
                         className={'filter-check-box'}
                         style={{
                          border: filters[category.title]?.includes(service.name)
                           ? '1px solid #0091FF'
                           : '1px solid #ccc',
                          background: filters[category.title]?.includes(service.name)
                           ? '#DAF4FF'
                           : 'transparent'
                         }}>
                         {filters[category.title]?.includes(service.name) && (
                          <div className={'tick-image'}>
                           <img alt="tick" width="100%" height="100%" src={tick} />
                          </div>
                         )}
                        </div>
                        <div style={{ color: '#1f1f1fb5' }} className={'option-label'}>
                         {service.name}
                        </div>
                       </button>
                      </div>
                     ))}
                    </div>
                   </div>
                  </div>
                 );
                } else {
                 return null;
                }
               })}

               {category?.options?.every((serviceCategory) => {
                const filteredServices = serviceCategory.services.filter((service) =>
                 service.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
                const categoryMatch = serviceCategory.category
                 .toLowerCase()
                 .includes(searchTerm.toLowerCase());

                return !(categoryMatch || filteredServices?.length > 0);
               }) && (
                <div className={'no-result-dropdown-field-container'}>
                 <div className={'search-field'}>
                  <div className={'dropdown-option-name'}>No results found</div>
                 </div>
                </div>
               )}
              </div>
             ) : filteredOptions?.length > 0 ? (
              filteredOptions?.map((options, i) => (
               <div
                key={createKey(i, options.name)}
                className={`${'dropdown-field-container'} ${i === selectedIndex ? 'selected' : ''}`}>
                <button
                 onClick={() => handleCheckboxToggle(category.title, options.name)}
                 className={'option-field'}>
                 <div
                  className={'filter-check-box'}
                  style={{
                   border: filters[category.title]?.includes(options.name)
                    ? '1px solid #0091FF'
                    : '1px solid #ccc',
                   background: filters[category.title]?.includes(options.name)
                    ? '#DAF4FF'
                    : 'transparent'
                  }}>
                  {filters[category.title]?.includes(options.name) && (
                   <div className={'tick-image'}>
                    <img alt="tick" width="100%" height="100%" src={tick} />
                   </div>
                  )}
                 </div>
                 <div className={'option-label'}>{options.name}</div>
                </button>
               </div>
              ))
             ) : (
              <div className={'no-result-dropdown-field-container'}>
               <div className={'search-field'}>
                <div className={'dropdown-option-name'}>No results found</div>
               </div>
              </div>
             )}
            </div>
           </div>
          </div>
         )}
        </div>
       </div>
      );
     })}
    </div>
   </div>
  </div>
 );
};

export default AgencyForm;

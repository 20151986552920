import React, { useEffect, useState } from 'react';
import 'Styles/pagination.css';
import nextArrow from 'Assets/nextArrow.svg';
import prevarrow from 'Assets/prevArrow.svg';

const CustomPagination = ({ pageNumber, setPageNumber, pageCount }) => {
 //********************************************************Constants***************************************************//

 const [tempPageNumber, setTempPageNumber] = useState(pageNumber);

 //********************************************************UseEffects***************************************************//

 //Storing the current page number in ducks
 useEffect(() => {
  setTempPageNumber(pageNumber);
 }, [pageNumber]);

 //********************************************************Functions***************************************************//

 //Move to the previous page
 const handlePrev = () => {
  if (pageNumber > 1) {
   setPageNumber(pageNumber - 1);
  }
 };

 //Move to the next page
 const handleNext = () => {
  if (pageNumber < pageCount) {
   setPageNumber(Number(pageNumber) + 1);
  }
 };

 //Stores the page number in onchnage
 const handleInputChange = (e) => {
  const value = e.target.value;
  setTempPageNumber(value);
 };

 //After editing the page number when the enter button is clicked it moves to that page
 const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
   const newPageNumber = Number(tempPageNumber);
   if (newPageNumber >= 1 && newPageNumber <= pageCount) {
    setPageNumber(newPageNumber);
   } else {
    setTempPageNumber(pageNumber);
   }
  }
 };

 //********************************************************html***************************************************//

 return (
  <>
   {window?.innerWidth > 991 ? (
    <div className="pagination-container">
     <button
      className="page-shift-button"
      style={{ color: pageNumber === 1 ? '#979797' : '' }}
      onClick={handlePrev}
      disabled={pageNumber === 1}>
      <div className="arrow-image">
       <img alt="prev" src={nextArrow} width="100%" height="100%" />
      </div>
      <div>Prev</div>
     </button>
     <div className="page-content">
      <input
       style={{ textAlign: 'center' }}
       minLength={1}
       maxLength={pageCount}
       onChange={handleInputChange}
       onKeyDown={handleKeyPress}
       value={tempPageNumber}
       className="number-box"
      />
      of <span>{pageCount}</span>
     </div>
     <button
      className="page-shift-button"
      style={{ color: pageNumber === pageCount ? '#979797' : '' }}
      onClick={handleNext}
      disabled={pageNumber === pageCount}>
      <div>Next</div>
      <div className="arrow-image">
       <img alt="prev" src={prevarrow} width="100%" height="100%" />
      </div>
     </button>
    </div>
   ) : (
    <div className="responsive-pagination-container">
     <div className="page-content">
      Page
      <input
       style={{ textAlign: 'center' }}
       minLength={1}
       maxLength={pageCount}
       onChange={handleInputChange}
       onKeyDown={handleKeyPress} // Listen for Enter key press
       value={tempPageNumber} // Bind the input to tempPageNumber state
       className="number-box"
      />
      of <span>{pageCount}</span>
     </div>
     <div className="button-container">
      <button
       className="page-shift-button"
       style={{ color: pageNumber === 1 ? '#979797' : '' }}
       onClick={handlePrev}
       disabled={pageNumber === 1}>
       <div className="arrow-image">
        <img alt="prev" src={nextArrow} width="100%" height="100%" />
       </div>
       <div>Prev</div>
      </button>

      <button
       className="page-shift-button"
       style={{ color: pageNumber === pageCount ? '#979797' : '' }}
       onClick={handleNext}
       disabled={pageNumber === pageCount}>
       <div>Next</div>
       <div className="arrow-image">
        <img alt="prev" src={prevarrow} width="100%" height="100%" />
       </div>
      </button>
     </div>
    </div>
   )}
  </>
 );
};

export default CustomPagination;

import React, { useEffect, useState } from 'react';
import 'Styles/LandingPage/landingSection.css';
import urls from 'Config/url';
import Button from '../CommonComponents/button';
import { useNavigate } from 'react-router-dom';
import simpleStroke from 'Assets/Landing/simpleStrokeBg.svg';
import simplestartleft from 'Assets/Landing/simpleStarleft.png';
import simplestartright from 'Assets/Landing/simpleStarRight.png';
import responsivesimpleStroke from 'Assets/Landing/responsiveSimpleStrokeBg.svg';

const LandingHireNextProjectSimple = () => {
 //********************************************************Constants***************************************************//

 const navigate = useNavigate();
 const [windowWidth, setWindowWidth] = useState(0);

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  const handleResize = () => {
   setWindowWidth(window.innerWidth);
  };

  handleResize();
  window.addEventListener('resize', handleResize);

  return () => {
   window.removeEventListener('resize', handleResize);
  };
 }, []);

 //********************************************************Function***************************************************//

 //Navigate to login page
 const handleRoute = () => {
  navigate(urls.URL_AUTH);
 };

 //********************************************************html***************************************************//

 return (
  <div
   className="simple-box"
   style={{
    backgroundImage: windowWidth > 991 ? `url(${simpleStroke})` : `url(${responsivesimpleStroke})`
   }}>
   <div
    className="simple-container"
    style={{ backgroundImage: `url(${simplestartleft}),url(${simplestartright})` }}>
    <div className="simple-contents">
     <div className="simple-heading">Your next project made simple</div>
     <div className="simple-description">
      Hire now with Tokai for on-demand, high-quality, vetted development agencies that fit your
      timeline and budget.
     </div>
    </div>
    <div className="simple-button" onClick={() => handleRoute()}>
     <Button.Primary hoverType="hover1" content="Hire with Confidence" />
    </div>
   </div>
  </div>
 );
};

export default LandingHireNextProjectSimple;

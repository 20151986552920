import React from 'react';
import DOMPurify from 'dompurify';

const setNamespace = (namespace) => (constant) => {
 return `${namespace}/${constant}`;
};

const uniqKey = (key) => {
 return (key || '').replace(/[^a-zA-Z0-9]/g, '-');
};

const setApiError = (dispatch, callback, error) => {
 dispatch(
  callback('apiError', {
   error: error.response?.data?.error,
   status: error.response?.status
  })
 );
 setTimeout(() => {
  dispatch(callback('apiError', null));
 });
};

// make the first letter caps
const capitalizeFirstChar = (text) => {
 return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

// make the every word's first letter caps
const capitalizeEveryFirstChar = (text) => {
 const words = text.split(' ');
 return words?.map((word) => capitalizeFirstChar(word)).join(' ');
};

// make the code based on name
const makeCode = (text) => {
 return text.split(' ').join('').toUpperCase();
};

const sanitaizeDOM = (tag) => {
 var clean = DOMPurify.sanitize(tag);
 return clean;
};

const formStoreData = (props, namespaces = []) => {
 let store = {};
 let actions = {};

 namespaces.forEach((namespace) => {
  store = { ...store, ...props.store[`${namespace}Store`] };
  actions = { ...actions, ...props.actions[`${namespace}Actions`] };
 });

 return {
  store,
  actions,
  history: props.history,
  location: props.location
 };
};

const createKey = (index, string) => {
 const safeString = typeof string === 'string' ? string : '';
 return `${index}${safeString.replace(/\s+/g, '')}`;
};

function checkValidBusinessMail(mail) {
 const regex =
  /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn|test)\..+)(^[^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
 return regex.test(mail);
}
function checkValidMail(email) {
 const regex = /^[^\s@]+@[^\s@]+\.(com)$/;
 return regex.test(email);
}

const truncateText = (text, maxLength, description) => {
 if (text.length > maxLength) {
  return description ? `${text.substring(0, maxLength)}` : `${text.substring(0, maxLength)}...`;
 }
 return text;
};

const validateMobile = (mobile) => {
 const regex = /^\d{10}$/;
 return regex.test(mobile);
};

const calculateTime = (timestamp) => {
 const now = new Date();
 const pastDate = new Date(timestamp);
 const secondsAgo = Math.floor((now - pastDate) / 1000);

 const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

 if (secondsAgo < 60) {
  return rtf.format(-secondsAgo, 'second');
 }
 if (secondsAgo < 3600) {
  return rtf.format(-Math.floor(secondsAgo / 60), 'minute');
 }
 if (secondsAgo < 86400) {
  return rtf.format(-Math.floor(secondsAgo / 3600), 'hour');
 }
 return rtf.format(-Math.floor(secondsAgo / 86400), 'day');
};

const onImageError = (e) => {
 const placeholderImage =
  'https://t4.ftcdn.net/jpg/07/86/72/89/360_F_786728988_QyuP5WkUfZMlGMEMltILI72HWVtkEyYx.jpg';
 e.target.src = placeholderImage;
};

export {
 setNamespace,
 onImageError,
 uniqKey,
 setApiError,
 capitalizeFirstChar,
 capitalizeEveryFirstChar,
 makeCode,
 sanitaizeDOM,
 formStoreData,
 calculateTime,
 validateMobile,
 checkValidBusinessMail,
 checkValidMail,
 createKey,
 truncateText
};

import React from 'react';
import 'Styles/chip.css';

const Chip = ({ title, handleClick }) => {
 return (
  <button onClick={handleClick} className={'chip'}>
   {title}
  </button>
 );
};

export default Chip;

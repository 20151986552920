import cloneDeep from 'lodash/cloneDeep';
import { setNamespace, setApiError } from 'Utilities/helpers';
import Network from 'Utilities/network';

const namespace = 'dashboard';
const createAction = setNamespace(namespace);
const nw = new Network();

// STORE
const initialState = {
 apiError: null,
 topAgency: null,
 category: null,
 id: null,
 user: null,
 details: null,
 filters: null,
 submited_brief: null,
 currentpageNumber: 1,
 currentFilterValues: {},
 modalopen: false
};

// ACTIONS

const ASSIGN_TO_DASHBOARD_STORE = createAction('ASSIGN_TO_DASHBOARD_STORE');
const RESET_DASHBOARD_STORE = createAction('RESET_DASHBOARD_STORE');

const assignToDashboardStore = (type, payload) => ({
 type: ASSIGN_TO_DASHBOARD_STORE,
 meta: {
  type,
  payload
 }
});

const resetDashboardStore = () => (dispatch) => {
 dispatch({
  type: RESET_DASHBOARD_STORE,
  meta: {
   payload: null
  }
 });
};

// METHODS
const getTopAgency = () => (dispatch) => {
 dispatch(assignToDashboardStore('topAgency', null));
 return nw
  .api('get_topAgency')
  .get()
  .then((response) => {
   dispatch(assignToDashboardStore('topAgency', response?.data?.data?.attributes));
  })
  .catch((error) => {
   setApiError(dispatch, assignToDashboardStore, error);
  });
};

const postProjectDetails = (data) => (dispatch) => {
 dispatch(assignToDashboardStore('submited_brief', null));
 return nw
  .api('projects')
  .post(data)
  .then((response) => {
   dispatch(assignToDashboardStore('submited_brief', response?.data?.data?.attributes));
  })
  .catch((error) => {
   setApiError(dispatch, assignToDashboardStore, error);
  });
};

const getFilters = () => (dispatch) => {
 dispatch(assignToDashboardStore('filters', null));
 return nw
  .api('filter')
  .get()
  .then((response) => {
   dispatch(assignToDashboardStore('filters', response?.data));
  })
  .catch((error) => {
   setApiError(dispatch, assignToDashboardStore, error);
  });
};

const currentpage = (data) => (dispatch) => {
 dispatch(assignToDashboardStore('currentpageNumber', data));
};
const currentFilters = (data) => (dispatch) => {
 dispatch(assignToDashboardStore('currentFilterValues', data));
};
const setModalOpen = (data) => (dispatch) => {
 dispatch(assignToDashboardStore('modalopen', data));
};
//Use If need DB json
// const getAllRequetUser = () => (dispatch) => {
//   dispatch(assignToDashboardStore("get_Posts", null));
//   return nw
//     .api("get_Posts")
//     .get()
//     .then((response) => {
//       dispatch(assignToDashboardStore("testData", response?.data));
//     }).catch((error) => {
//       setApiError(dispatch, assignToDashboardStore, error);
//     });
// };

// Routing

// Reducers
const dashboardReducer = (state = initialState, action) => {
 const localState = cloneDeep(state);

 switch (action.type) {
  case ASSIGN_TO_DASHBOARD_STORE:
   localState[action.meta.type] = action.meta.payload;
   return { ...localState };
  case RESET_DASHBOARD_STORE:
   return initialState;
  default:
   return localState;
 }
};

export default {
 namespace,
 store: initialState,
 reducer: dashboardReducer,
 creators: {
  assignToDashboardStore,
  resetDashboardStore,
  getTopAgency,
  postProjectDetails,
  getFilters,
  currentpage,
  currentFilters,
  setModalOpen
 }
};

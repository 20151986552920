import React, { useState } from 'react';
import { createKey, truncateText } from 'Utilities/helpers.js';
import 'Styles/reviews.css';
import { calculateTime } from 'Utilities/helpers';
import star from 'Assets/rating.svg';
import lowStar from 'Assets/lowRating.svg';
import averageStar from 'Assets/averageRating.svg';
import profileImage from 'Assets/profile.svg';

const Reviews = ({ reviews }) => {
 const [checkReviewTruncate, setCheckReviewTruncate] = useState({});

 //********************************************************Functions***************************************************//

 //Toggles to see the particular review description
 const handleTruncateToggle = (id) => {
  setCheckReviewTruncate((prev) => ({
   ...prev,
   [id]: !prev[id] // Toggle truncation state for the specific review
  }));
 };

 //Changing the star image according to the ratings
 const getStarImage = (rating) => {
  if (rating < 3) {
   return lowStar;
  }
  if (rating >= 4) {
   return star;
  }
  return averageStar;
 };

 //Setting broken image
 const onImageError = (e) => {
  e.target.src = profileImage;
 };

 //********************************************************html***************************************************//

 return (
  <>
   {reviews?.map((data, index) => (
    <div key={createKey(index, data.Name, data.id)} className={'review-header'}>
     <div className={'rating-container'}>
      <div className={'review-content'}>
       <div className={'profile-pic'}>
        <img
         onError={onImageError}
         alt="profile"
         src={data?.Profile ? data?.Profile : profileImage}
         width="100%"
         height="100%"
        />
       </div>
       <div className={'reviewver-details-container'}>
        <div className={'reviewver-details'}>
         <div className={'reviewver-name'}>{data?.Name}</div>
         <div className={'ratings'}>
          <div className={'image-container'}>
           <img alt="arrow" src={getStarImage(data?.Rating)} width="100%" height="100%" />
          </div>
          <div className={'value'}>{data?.Rating}/ 5</div>
         </div>
        </div>
        <div className={'position'}>{data?.About}</div>
       </div>
      </div>
      <div className={'ratings-and-time'}>
       <div className={'ratings'}>
        <div className={'value'}>{calculateTime(data?.updatedAt)}</div>
       </div>
      </div>
     </div>
     <div className={'comment'}>
      {checkReviewTruncate[data.id] ? data.Review : truncateText(data.Review, 290, 'description')}

      {data.Review?.length > 290 && !checkReviewTruncate[data.id] && (
       <span onClick={() => handleTruncateToggle(data.id)}>...see more</span>
      )}

      {checkReviewTruncate[data.id] && (
       <span onClick={() => handleTruncateToggle(data.id)}>see less</span>
      )}
     </div>
    </div>
   ))}
  </>
 );
};
export default Reviews;

import React from 'react';

import RefProvider from 'Utilities/refProvider';
import RefErrorBoundary from 'Utilities/refErrorBoundary';
import { formStoreData } from 'Utilities/helpers';
import AgencyDetail from './Agency';

const Agency = (props) => {
 const propShape = formStoreData(props, ['agency', 'dashboard', 'auth']);
 return (
  <>
   <RefProvider data={propShape}>
    <RefErrorBoundary {...props}>
     <AgencyDetail />
    </RefErrorBoundary>
   </RefProvider>
  </>
 );
};

export default Agency;

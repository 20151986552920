import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const fetchRoutes = (containers) => {
 const { Home, AgencyLists, Agency, TopAgency, Login, ProjectDetailForm, Landing } = containers;

 return function Routes() {
  const createRoutes = () => {
   return createBrowserRouter([
    {
     path: '/home',
     element: <Home />,
     errorElement: <div>404 No Page Found</div>
    },
    {
     path: '/',
     element: <Landing />,
     errorElement: <div>404 No Page Found</div>
    },
    {
     path: '/login',
     element: <Login />,
     errorElement: <div>404 No Page Found</div>
    },
    {
     path: '/reset-password',
     element: <Login />,
     errorElement: <div>404 No Page Found</div>
    },
    {
     path: '/:slug',
     element: <AgencyLists />,
     errorElement: <div>404 No Page Found</div>
    },
    {
     path: '/:slug/:companyName',
     element: <Agency />,
     errorElement: <div>404 No Page Found</div>
    },
    {
     path: '/top-agency',
     element: <TopAgency />,
     errorElement: <div>404 No Page Found</div>
    },
    {
     path: '/project-detail',
     element: <ProjectDetailForm />,
     errorElement: <div>404 No Page Found</div>
    }
   ]);
  };
  return (
   <>
    <RouterProvider router={createRoutes()} />
   </>
  );
 };
};

export { fetchRoutes };

import React, { useContext, useEffect, useState } from 'react';
import RefContext from 'Utilities/refContext';
import 'Styles/topAgency.css';
import Sidebar from 'Components/UI/CommonComponents/sidebar';
import { createKey } from 'Utilities/helpers';
import SidebarRight from 'Components/UI/CommonComponents/sidebarRight';
import { useNavigate } from 'react-router-dom';
import CategoryTypes from 'Components/UI/TopAgencyPage/categorytypes';
import MainContentHeader from 'Components/UI/CommonComponents/mainContentHeader';
import trophy from 'Assets/trophy.svg';
import { Helmet } from 'react-helmet';
import activecode from 'Assets/Categories/activeCodeimage.svg';
import activeservicelines from 'Assets/Categories/activeServicelines.svg';
import activedomainfocus from 'Assets/Categories/activeDomainfocus.svg';
import activecms from 'Assets/Categories/activeCmssolution.svg';
import activeprogram from 'Assets/Categories/activeDeviceMobile.svg';
import activeframework from 'Assets/Categories/activeFramework.svg';
import activesoftware from 'Assets/Categories/activeCustomsoftware.svg';
import activedevops from 'Assets/Categories/activeUsersFour.svg';
import activeai from 'Assets/Categories/activeAi.svg';
import activemobile from 'Assets/Categories/activeCube.svg';
import activeqa from 'Assets/Categories/activeNote.svg';
import activeitconsultancy from 'Assets/Categories/activeItconsulting.svg';
import activedesign from 'Assets/Categories/activePaintBrush.svg';
import activeecommerce from 'Assets/Categories/activeStorefront.svg';
import activegame from 'Assets/Categories/activeGamedevelopment.svg';

function Agencies() {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { getTopAgency, currentpage, currentFilters } = actions;
 const { topAgency } = store;
 const [searchInput, setSearchInput] = useState('');
 const [storetopAgency, setStoreTopAgency] = useState([]);
 const [activeCategory, setActiveCategory] = useState(
  window?.innerWidth > 992 ? 'Service Lines Companies' : ''
 );
 const navigateTo = useNavigate();

 const categoryImages = [
  activeservicelines,
  activesoftware,
  activeecommerce,
  activedomainfocus,
  activecode,
  activeframework,
  activecms,
  activedesign,
  activeprogram,
  activemobile,
  activeitconsultancy,
  activeqa,
  activeai,
  activeecommerce,
  activemobile,
  activedevops,
  activegame
 ];

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  getTopAgency();
 }, []);

 useEffect(() => {
  setStoreTopAgency(topAgency);
 }, [topAgency]);

 //********************************************************Function***************************************************//

 const handleRouter = (category) => {
  currentpage(1);
  currentFilters({});
  navigateTo(`/${category?.slug}`, {
   state: { category: category?.name, location: category?.location, title: category?.title }
  });
 };

 //********************************************************html***************************************************//

 return (
  <div className="page-container">
   <Helmet>
    <title>{`Tokai | Top Agencies`}</title>
    <meta name="robots" content="noindex,nofollow" />
    {/* <meta name="description" content={agency?.description} /> */}
    <meta property="og:site_name" content={'Tokai'} />
   </Helmet>
   <div className="page-layout-container">
    <div className="page-layout">
     <div className="sidebar-section">
      <Sidebar />
     </div>

     <div className="main-section">
      <MainContentHeader topAgency={topAgency} />
      <div className="topagency-container">
       <div className="topagency-content">
        <div className="title-container">
         <div className="title">Top Software Development Agencies</div>
         <div className="trophy-image">
          <img className="searchImage" alt="trophy" src={trophy} width="100%" height="100%" />
         </div>
        </div>
        <div className={'content'}>
         We’ve handpicked top companies from across the globe, each specializing in unique
         technologies and solutions.
        </div>
       </div>
       {window?.innerWidth < 992 && (
        <CategoryTypes
         topAgency={storetopAgency}
         activeCategory={activeCategory}
         setActiveCategory={setActiveCategory}
        />
       )}
       {!storetopAgency && (
        <div className={'loader'}>
         <div className={'loader-progress'}></div>
        </div>
       )}
       {storetopAgency && (
        <div className={'categories-container'}>
         {storetopAgency?.topAgencies_category_and_locations?.map((categorytype, index) => (
          <div
           id={`${categorytype?.category}`}
           key={createKey(index, categorytype?.title)}
           className={'category-content-container'}>
           <div className={'category-types-container'}>
            <div className={'category-title-container'}>
             <div className={'category-image'}>
              <img alt="code" src={categoryImages[index]} width="100%" height="100%" />
             </div>
             <div className={'topAgency-category-title'}>{categorytype?.category}</div>
            </div>

            <>
             <div className={'category-type-title-container'}>
              <div className={'topAgency-category-type-title'}>Technologies</div>
             </div>
             <div className={'category-types-content'}>
              {storetopAgency?.topAgencies_category_and_services[index]?.services
               ?.slice(0, 5)
               ?.map((type, i) => (
                <button
                 onClick={() => handleRouter(type)}
                 key={createKey(i, type?.title)}
                 className={'category-type'}>
                 {type?.title}
                </button>
               ))}
             </div>
             <div className={'category-type-title-container'}>
              <div className={'topAgency-category-type-title'}>Locations</div>
             </div>
             <div className={'category-types-content'}>
              {categorytype?.locations?.slice(0, 5)?.map((type, i) => (
               <button
                onClick={() =>
                 handleRouter({
                  name: categorytype?.category,
                  slug: type?.slug,
                  location: type?.name,
                  title: type?.title
                 })
                }
                key={createKey(i, type?.title)}
                className={'category-type'}>
                {type?.title}
               </button>
              ))}
             </div>
            </>
           </div>
          </div>
         ))}
        </div>
       )}
      </div>
      <div className="responsive-sidebar">
       <Sidebar />
      </div>
     </div>

     <SidebarRight
      Content={
       <CategoryTypes
        topAgency={storetopAgency}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
       />
      }
      setSearchInput={setSearchInput}
      searchInput={searchInput}
     />
    </div>
   </div>
  </div>
 );
}

export default Agencies;

import React, { useState } from 'react';
import 'Styles/index.css';
import ProjectDetailsForm from 'Components/UI/ProjectBriefPage/projectDetails';
import BasicModal from 'Components/UI/CommonComponents/modal';
import Verification from 'Components/UI/ProjectBriefPage/verificationModal';
import ResponsiveHeader from 'Components/UI/CommonComponents/responsiveHeader';
import urls from 'Config/url';
import logo from 'Assets/logo.svg';
import title from 'Assets/title.svg';
import { useNavigate } from 'react-router-dom';
import verified from 'Assets/verified.svg';
import graphicelement from 'Assets/graphicElement.png';
import { Helmet } from 'react-helmet';
import cookie from 'react-cookies';

const ProjectDetail = () => {
 //********************************************************Constants***************************************************//

 const [projectDetailModalOpen, setProjectDetailModalOpen] = useState(false);
 const [verificationModalOpen, setVerificationModalOpen] = useState(false);
 const [searchInput, setSearchInput] = useState('');
 const [openPopUp, setOpenPopUp] = useState(false);
 const [authType, setAuthType] = useState('signin');
 const navigate = useNavigate();
 const user = cookie.load('access_token');
 const projectDetailsModalProps = {
  Content: ProjectDetailsForm,
  setOpen: setProjectDetailModalOpen,
  open: projectDetailModalOpen,
  setOpenPopUp,
  setAuthType
 };
 const verificationModalProps = {
  Content: Verification,
  setOpen: setVerificationModalOpen,
  open: verificationModalOpen,
  setOpenPopUp,
  setAuthType
 };

 //Navigate to the landing page if it is not logged in else navigate to the home page
 const authHandle = () => {
  if (user) {
   navigate(urls?.URL_HOME);
  } else {
   navigate(urls?.URL_LANDING);
  }
 };

 //********************************************************html***************************************************//

 return (
  <div id="page-container" className={'page-container'}>
   <Helmet>
    <title>{`Tokai | ProjectDetail`}</title>
    <meta name="robots" content="noindex,nofollow" />
    <meta property="og:site_name" content={'Tokai'} />
   </Helmet>
   <>
    <div className={'landing-page-container'}>
     <div className={'logo-container'}>
      <div onClick={() => authHandle()} className={'logo-content'}>
       <div className={'logo'}>
        <img alt="rect" src={logo} width="100%" height="100%" />
       </div>
       <div className={'title'}>
        <img alt="rect" src={title} width="68px" height="40px" />
       </div>
      </div>
     </div>
     <ResponsiveHeader setSearchInput={setSearchInput} searchInput={searchInput} />
     <div className={'project-page-layout'}>
      <div className={'project-page-left-container'}>
       <div className={'left-content'}>
        <div onClick={() => navigate(urls?.URL_HOME)} className={'logo-content'}>
         <div className={'logo'}>
          <img alt="rect" src={logo} width="100%" height="100%" />
         </div>
         <div className={'title'}>
          <img alt="rect" src={title} width="68px" height="40px" />
         </div>
        </div>
        <div className={'category-title'}>
         Delivering Trusted <span>Software Development Agencies to You</span>
        </div>
        <div className={'project-category-info'}>
         <div style={{ textDecoration: 'underline' }}>20,500+ Verified information </div>
         <div className={'verified-image'}>
          <img alt="lens" src={verified} width="100%" height="100%" />
         </div>
        </div>
       </div>
       <div className={'element-image'}>
        <img alt="rect" src={graphicelement} width="324px" height="260px" />
       </div>
      </div>

      <div className={'project-detail-form-box'}>
       <div className={'content-container'}>
        <div className={'line-container'}>
         <div className={'line-width'}></div>
         <div className={'line-width'}></div>
        </div>
        <ProjectDetailsForm setVerificationModalOpen={setVerificationModalOpen} />
       </div>
      </div>
     </div>
    </div>
    {verificationModalOpen && <BasicModal {...verificationModalProps} />}
    {projectDetailModalOpen && <BasicModal {...projectDetailsModalProps} />}
   </>
  </div>
 );
};
export default ProjectDetail;

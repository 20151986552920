import React, { useContext, useEffect, useState } from 'react';
import RefContext from 'Utilities/refContext';
import Sidebar from 'Components/UI/CommonComponents/sidebar';
import MainContentHeader from 'Components/UI/CommonComponents/mainContentHeader';
import MainContent from 'Components/UI/AgencyListPage/mainContent';
import 'Styles/index.css';
import CustomPagination from 'Components/UI/AgencyListPage/pagination';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SidebarRight from 'Components/UI/CommonComponents/sidebarRight';
import { createKey } from 'Utilities/helpers';
import AgencyForm from 'Components/UI/AgencyListPage/agencyForm';
import { Helmet } from 'react-helmet';

const AgencyListPage = () => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { getAgencyList, getFilters, getTopAgency, currentpage, currentFilters } = actions;
 const { agencyList, topAgency, filters, meta, currentpageNumber, currentFilterValues } = store;
 const [pageNumber, setPageNumber] = useState(currentpageNumber);
 const [pageCount, setPageCount] = useState(null);
 const [comapanyNumber, setCompanyNumber] = useState(null);
 const [otherTopServices, setOtherTopServices] = useState([]);
 const [filteredOptions, setFilterOptions] = useState(currentFilterValues);
 const [searchInput, setSearchInput] = useState('');
 const navigate = useNavigate();
 const [mainfilter, setmainFilter] = useState('');
 const { slug } = useParams();
 const [categoryName, setCategoryName] = useState('');
 const location = useLocation();

 //********************************************************UseEffects***************************************************//

 useEffect(() => {
  getFilters();
  if (!topAgency) {
   getTopAgency();
  }
 }, []);

 useEffect(() => {
  currentFilters(filteredOptions);
 }, [filteredOptions]);

 useEffect(() => {
  currentpage(pageNumber);
 }, [pageNumber]);

 //Extracting the other top categories related to the current category
 useEffect(() => {
  let matchFound = false;

  if (topAgency?.topAgencies_category && !matchFound && categoryName) {
   topAgency.topAgencies_category.forEach((entry) => {
    if (entry?.title?.toLowerCase() === categoryName.toLowerCase()) {
     setOtherTopServices([]);
     setOtherTopServices((prevState) => [...prevState, ...topAgency?.topAgencies_category]);
     matchFound = true;
    }
   });
  }

  if (topAgency?.topAgencies_category_and_services && !matchFound && categoryName) {
   topAgency.topAgencies_category_and_services.forEach((category) => {
    category?.services?.forEach((data) => {
     if (data?.title?.toLowerCase() === categoryName.toLowerCase()) {
      setOtherTopServices([]);
      setOtherTopServices((prevState) => [...prevState, ...category?.services]);
      matchFound = true;
     }
    });
   });
  }
  if (topAgency?.topAgencies_category_and_locations && !matchFound && categoryName) {
   topAgency.topAgencies_category_and_locations.forEach((category) => {
    category?.locations?.forEach((data) => {
     if (data?.title?.toLowerCase() === categoryName.toLowerCase()) {
      setOtherTopServices([]);
      setOtherTopServices((prevState) => [...prevState, ...category?.locations]);
      matchFound = true;
     }
    });
   });
  }
 }, [agencyList]);

 useEffect(() => {
  setPageCount(meta?.pagination?.pageCount);
  setCompanyNumber(agencyList?.meta?.pagination?.total);
 }, [agencyList, meta]);

 useEffect(() => {
  const formattedCompanyName = slug
   .split('-')
   ?.map((word) => {
    if (word.toLowerCase() === 'in') {
     return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
   })
   .join(' ');

  setCategoryName(formattedCompanyName);
  splitData(formattedCompanyName);
 }, [slug]);

 useEffect(() => {
  if (makeFilterQuery()) {
   getAgencyList(pageNumber, makeFilterQuery());
  }
 }, [filteredOptions, pageNumber, mainfilter]);

 useEffect(() => {
  if (location?.state?.filters?.Domain && location?.state?.filters?.MinProjectSize) {
   setFilterOptions(location?.state?.filters);
  }
 }, [location]);

 //********************************************************Functions***************************************************//

 //Creating the url according to the selected filters
 const makeFilterQuery = () => {
  const queries = [];
  if (mainfilter && mainfilter?.category !== '')
   queries.push(`filters[$and][0][servicesFocus][$containsi]=${mainfilter?.category}`);
  if (mainfilter && mainfilter?.location !== '')
   queries.push(`filters[$and][0][locations][$containsi]=${mainfilter?.location}`);
  if (filteredOptions?.Category)
   queries.push(
    ...filteredOptions.Category?.map(
     (value) => `filters[servicesFocus][$containsi]=${encodeURIComponent(value)}`
    )
   );
  if (filteredOptions?.ClientFocus)
   queries.push(
    ...filteredOptions.ClientFocus?.map(
     (value) => `filters[clientFocus][$containsi]=${encodeURIComponent(value)}`
    )
   );
  if (filteredOptions?.CompanySize)
   queries.push(
    ...filteredOptions.CompanySize?.map(
     (value) => `filters[employees][$eq]=${encodeURIComponent(value)}`
    )
   );
  if (filteredOptions?.Domain)
   queries.push(
    ...filteredOptions.Domain?.map(
     (value) => `filters[servicesFocus][$containsi]=${encodeURIComponent(value)}`
    )
   );
  if (filteredOptions?.ServiceLine)
   queries.push(
    ...filteredOptions.ServiceLine?.map(
     (value) => `filters[servicesFocus][$containsi]=${encodeURIComponent(value)}`
    )
   );
  if (filteredOptions?.Frameworks)
   queries.push(
    ...filteredOptions.Frameworks?.map(
     (value) => `filters[servicesFocus][$containsi]=${encodeURIComponent(value)}`
    )
   );
  if (filteredOptions?.HourlyRate)
   queries.push(
    ...filteredOptions.HourlyRate?.map(
     (value) => `filters[hourlyRate][$eq]=${encodeURIComponent(value)}`
    )
   );
  if (filteredOptions?.Language)
   queries.push(
    ...filteredOptions.Language?.map(
     (value) => `filters[servicesFocus][$containsi]=${encodeURIComponent(value)}`
    )
   );
  if (filteredOptions?.Location)
   queries.push(
    ...filteredOptions.Location?.map(
     (value) => `filters[locations][$containsi]=${encodeURIComponent(value)}`
    )
   );
  if (filteredOptions?.MinProjectSize)
   queries.push(
    ...filteredOptions.MinProjectSize?.map(
     (value) => `filters[minProjectSize][$eq]=${encodeURIComponent(value)}`
    )
   );
  return queries.join('&');
 };

 //Navigate to the particular company profile page
 const handleRouter = (category) => {
  setPageNumber(1);
  navigate(`/${category?.slug}`, {
   state: { category: category?.category || category?.name, title: category?.title }
  });
 };

 //Extracting the filter option from the url
 const splitData = (data) => {
  const cleanData = data
   .replace(/^Top\s+/i, '')
   .replace(/\s+Companies/i, '')
   .trim();

  const locationMatch = cleanData.match(/\bin\s+(.+)$/i);

  let category = cleanData;
  let location = '';

  if (locationMatch) {
   const categorydata = data
    .replace(/^Top\s+/i, '')
    .replace(/\s+Companies/i, '')
    .trim();

   category = categorydata.replace(/\bin\s+(.+)$/i, '').trim();

   location = locationMatch[1].trim();
  }

  setmainFilter({ category, location });
 };

 const agencyFormHandler = () => {
  return (
   <AgencyForm
    dropdownMenus={filters}
    setFilterOptions={setFilterOptions}
    setPageNumber={setPageNumber}
    filterOption={filteredOptions}
    categoryName={mainfilter?.category}
   />
  );
 };

 //********************************************************html***************************************************//

 return (
  <div className={'page-container'}>
   <Helmet>
    <title>{`Tokai | ${mainfilter ? mainfilter?.category : 'Loading..'}`}</title>
    <meta name="robots" content="noindex,nofollow" />
    <meta property="og:site_name" content={'Tokai'} />
   </Helmet>
   <>
    <div className={'page-layout-container'}>
     <div className={'page-layout'}>
      <div className={'sidebar-section'}>
       <Sidebar />
      </div>
      <div id="main-section" className={'main-section'}>
       <MainContentHeader topAgency={topAgency} />
       <MainContent
        cardInfo={agencyList?.data || []}
        dropdownMenus={filters}
        setPageCount={setPageCount}
        pageNumber={pageNumber}
        comapanyNumber={comapanyNumber}
        setPageNumber={setPageNumber}
        setFilterOptions={setFilterOptions}
        filterOption={filteredOptions}
        mainfilter={mainfilter}
       />
       <div className={'pagination'}>
        <CustomPagination
         pageNumber={pageNumber}
         setPageNumber={setPageNumber}
         pageCount={pageCount}
        />
       </div>
       {otherTopServices?.length > 0 && (
        <div className={'top-services-container'}>
         <div className={'top-service-title'}>Other top services</div>
        </div>
       )}
       <div className={'top-service-values'}>
        {otherTopServices?.slice(0, 10)?.map((services, index) => (
         <button
          onClick={() => handleRouter(services)}
          key={createKey(index, services?.title)}
          className={'top-services'}>
          {services?.title}
         </button>
        ))}
       </div>
       <div className={'responsive-sidebar'}>
        <Sidebar />
       </div>
      </div>
      <SidebarRight
       Content={agencyFormHandler()}
       setSearchInput={setSearchInput}
       searchInput={searchInput}
      />
     </div>
    </div>
   </>
  </div>
 );
};

export default AgencyListPage;

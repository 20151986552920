import React, { useEffect, useRef } from 'react';
import 'Styles/agencyDetails.css';
import { useState } from 'react';
import { createKey, truncateText } from 'Utilities/helpers';
import Chip from './chip';
import BreadCrumbs from '../CommonComponents/breadcrumbs';
import suitCaseIcon from 'Assets/suitcaseSimple.svg';
import usersThreeIcon from 'Assets/usersThree.svg';
import handshakeIcon from 'Assets/handshake.svg';
import gpsIcon from 'Assets/gpsFix.svg';
import dollar from 'Assets/currencyCircleDollar.svg';
import calendar from 'Assets/calendarBlank.svg';
import building from 'Assets/building.svg';
import ratingsStar from 'Assets/star.svg';
import Button from '../CommonComponents/button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import instgramLogo from 'Assets/instagramLogo.svg';
import facebookLogo from 'Assets/FacebookLogo.svg';
import xLogo from 'Assets/XLogo.svg';
import linkedinLogo from 'Assets/LinkedinLogo.svg';
// import emailLogo from 'Assets/emailLogo.svg';
import phoneLogo from 'Assets/phoneLogo.svg';
import Reviews from './reviews';
import urls from 'Config/url';
import arrowDown from 'Assets/arrowDown.svg';
import { useNavigate, useParams } from 'react-router-dom';
import cookie from 'react-cookies';
import { onImageError } from 'Utilities/helpers';
import activecode from 'Assets/Categories/activeCodeimage.svg';
import activeservicelines from 'Assets/Categories/activeServicelines.svg';
import activedomainfocus from 'Assets/Categories/activeDomainfocus.svg';
import activecms from 'Assets/Categories/activeCmssolution.svg';
import activeprogram from 'Assets/Categories/activeDeviceMobile.svg';
import activeframework from 'Assets/Categories/activeFramework.svg';
import activesoftware from 'Assets/Categories/activeCustomsoftware.svg';
import activedevops from 'Assets/Categories/activeUsersFour.svg';
import activeai from 'Assets/Categories/activeAi.svg';
import activemobile from 'Assets/Categories/activeCube.svg';
import activeqa from 'Assets/Categories/activeNote.svg';
import activeitconsultancy from 'Assets/Categories/activeItconsulting.svg';
import activedesign from 'Assets/Categories/activePaintBrush.svg';
import activeecommerce from 'Assets/Categories/activeStorefront.svg';
import activegame from 'Assets/Categories/activeGamedevelopment.svg';

const AgencyDetails = ({ agencyDetails }) => {
 //********************************************************Constants***************************************************//

 const [noOfReviewsToLoad, setNoOfReviwesToLoad] = useState(3);
 const { slug } = useParams();
 const navigate = useNavigate();
 const [categoryName, setCategoryName] = useState('');
 const [checkTruncate, setCheckTruncate] = useState(false);

 const categoryImages = [
  { category: 'Service Lines', icon: activeservicelines },
  { category: 'Custom Software Development', icon: activesoftware },
  { category: 'Ecommerce', icon: activeecommerce },
  { category: 'Domain focus', icon: activedomainfocus },
  { category: 'Programming language', icon: activecode },
  { category: 'Frameworks', icon: activeframework },
  { category: 'CMS solutions', icon: activecms },
  { category: 'Design', icon: activedesign },
  { category: 'Mobile Development', icon: activeprogram },
  { category: 'Devops', icon: activemobile },
  { category: 'It Consulting', icon: activeitconsultancy },
  { category: 'Qa And Testing', icon: activeqa },
  { category: 'Artificial Intelligence', icon: activeai },
  { category: 'Big Data', icon: activeecommerce },
  { category: 'Blockchain', icon: activemobile },
  { category: 'Cloud Consulting', icon: activedevops },
  { category: 'Game Development', icon: activegame }
 ];
 const keyInfo = [
  { icon: dollar, content: agencyDetails?.hourlyRate ? agencyDetails?.hourlyRate : '' },
  { icon: usersThreeIcon, content: agencyDetails?.employees ? agencyDetails?.employees : '' },
  {
   icon: building,
   content: `Since ${agencyDetails?.yearOfFoundation ? agencyDetails?.yearOfFoundation : ''}`
  },
  { icon: suitCaseIcon, content: agencyDetails?.clientFocus ? agencyDetails?.clientFocus : '' },
  {
   icon: handshakeIcon,
   content: agencyDetails?.minProjectSize
    ? agencyDetails?.minProjectSize === '<$5,000'
      ? 'Max $5,000'
      : `Min ${agencyDetails?.minProjectSize}`
    : ''
  },
  {
   icon: gpsIcon,
   content: agencyDetails?.locations[0]?.countryAndCity
    ? agencyDetails?.locations[0]?.countryAndCity
    : ''
  },
  { icon: calendar, content: agencyDetails?.readyToStart ? agencyDetails?.readyToStart : '' }
 ];
 const breadcrumbs = [
  {
   label: localStorage?.getItem('tab') === 'topAgency' ? 'Top Agencies' : 'Home',
   url: localStorage?.getItem('tab') === 'topAgency' ? urls?.URL_TOPAGENCY : urls.URL_HOME
  },
  {
   label: categoryName || 'Loading...',
   url: `/${slug}`
  },
  {
   label: agencyDetails?.companyName
  }
 ];
 const ratingsRef = useRef(null);

 const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: '100%',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
   backgroundColor: theme.palette.grey[200],
   ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[800]
   })
  },
  [`& .${linearProgressClasses.bar}`]: {
   borderRadius: 5,
   backgroundColor: '#1a90ff',
   ...theme.applyStyles('dark', {
    backgroundColor: '#308fe8'
   })
  }
 }));

 //********************************************************UseEffects***************************************************//

 //Extracting the company name from slug
 useEffect(() => {
  const formattedCompanyName = slug
   .split('-')
   ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
   .join(' ');
  setCategoryName(formattedCompanyName);
 }, [slug]);

 //********************************************************Functions***************************************************//

 //Scrolls to the ratings and review section
 const scrollToRatings = () => {
  if (ratingsRef.current) {
   ratingsRef.current.scrollIntoView({ behavior: 'smooth' });
  }
 };

 // Loads all reviews by setting the number of reviews to the total length of the reviews array
 const loadMoreReviews = () => {
  setNoOfReviwesToLoad(agencyDetails?.ratings_and_reviews?.length);
 };

 //Open a website page in new tab
 const handleVisitWebsite = () => {
  window.open(agencyDetails?.websiteUrl, '_blank');
 };

 //********************************************************html***************************************************//

 return (
  <div className={'body'}>
   <div className={'breadcrumb-container'}>
    <BreadCrumbs breadcrumbs={breadcrumbs} />
   </div>
   {!agencyDetails && (
    <div className={'loader'}>
     <div className={'loader-progress'}></div>
    </div>
   )}
   {agencyDetails && (
    <div>
     <div className={'agency-detail-container'}>
      <div className={'agency-detail-title'}>
       <div className={'card-box'}>
        {agencyDetails?.logoUrl && (
         <img
          alt="info"
          onError={onImageError}
          src={agencyDetails?.logoUrl}
          width="100%"
          height="100%"
         />
        )}
       </div>
       <div className={'company-name-in-profile'}>{agencyDetails?.companyName}</div>
      </div>
     </div>
     <div className={'info-container'}>
      <div className={'info-title'}>Key Information</div>
      <div className={'key-info-body'}>
       {keyInfo?.map((info, index) => {
        return (
         <div key={createKey(index, info.content)} className={'info-content-container'}>
          <div className={'icon-wrapper'}>
           <img width="100%" height="100%" src={info?.icon} />
          </div>
          <div>{info?.content}</div>
         </div>
        );
       })}
      </div>
      <div className={'ratings-container'}>
       <div className={'ratings-sub-wrapper-1'}>
        <div>
         <img src={ratingsStar} alt="rating star" />
        </div>
        <div>{agencyDetails?.techreviewerRating}&nbsp;/&nbsp;5</div>
       </div>
       <div className={'ratings-sub-wrapper-2'}>
        {agencyDetails?.ratings_and_reviews?.length > 0 && (
         <div>&#40;Based on {agencyDetails?.ratings_and_reviews?.length} ratings&#41;</div>
        )}
        <div>&nbsp; - &nbsp;</div>
        <a
         style={{ cursor: 'pointer' }}
         onClick={() => scrollToRatings()}
         className={'view-all-btn'}>
         View all
        </a>
       </div>
      </div>
      <div className={'btn-wrapper'}>
       <Button.Secondary
        onClick={handleVisitWebsite}
        content="Visit Website"
        width="100%"
        size="large"
        disable={false}
       />
       <Button.Primary content="Book a call" width="100%" size="large" disable={true} />
      </div>
     </div>
     <div className={'info-container'}>
      <div className={'info-title'}>About</div>
      <div className={'sub-title-moto'}>{agencyDetails?.motto}</div>
      <div className={'company-description'}>
       {checkTruncate ? (
        <span dangerouslySetInnerHTML={{ __html: agencyDetails?.description }} />
       ) : (
        <span
         dangerouslySetInnerHTML={{
          __html: truncateText(agencyDetails?.description, 290, 'description')
         }}
        />
       )}
       {agencyDetails?.description?.length > 290 && !checkTruncate && (
        <span
         onClick={() => {
          setCheckTruncate(true);
         }}>
         ...see more
        </span>
       )}
       {checkTruncate && (
        <span
         onClick={() => {
          setCheckTruncate(false);
         }}>
         see less
        </span>
       )}
      </div>
     </div>
     <div className={'info-container'}>
      <div className={'info-title'}>{`${agencyDetails?.companyName}'s Contact information`}</div>
      <div className={'social-media-wrapper'}>
       {agencyDetails?.socialMedia__instagram && (
        <a href={agencyDetails?.socialMedia__instagram} target="_blank" rel="noopener noreferrer">
         <img alt="instagram" src={instgramLogo} />
        </a>
       )}
       {agencyDetails?.socialMedia__facebook && (
        <a href={agencyDetails?.socialMedia__facebook} target="_blank" rel="noopener noreferrer">
         <img alt="facebook" src={facebookLogo} />
        </a>
       )}
       {agencyDetails?.socialMedia__twitter && (
        <a href={agencyDetails?.socialMedia__twitter} target="_blank" rel="noopener noreferrer">
         <img alt="x" src={xLogo} />
        </a>
       )}
       {agencyDetails?.socialMedia__linkedin && (
        <a href={agencyDetails?.socialMedia__linkedin} target="_blank" rel="noopener noreferrer">
         <img alt="linkedin" src={linkedinLogo} />
        </a>
       )}
      </div>
      {/* <div className={'email-wrapper'}>
       <div>
        <img alt="email" src={emailLogo} />
       </div>
       <div>-</div>
      </div> */}
      {agencyDetails?.locations?.[0]?.phoneNumber !== '' && (
       <div className={'phone-wrapper'}>
        <div>
         <img alt="phonenumber" src={phoneLogo} />
        </div>
        {cookie.load('access_token') && agencyDetails?.locations?.[0]?.phoneNumber ? (
         <div className={'phone-number'}>{agencyDetails?.locations?.[0]?.phoneNumber}</div>
        ) : (
         <div className={'phone-number'}>
          {agencyDetails?.locations?.[0]?.phoneNumber?.length > 0
           ? agencyDetails?.locations?.[0]?.phoneNumber
           : ''}
         </div>
        )}
        {!cookie.load('access_token') && (
         <button
          onClick={() =>
           navigate(urls.URL_AUTH, {
            state: { isLoggin: true }
           })
          }
          className={'unlock-phoneno-btn'}>
          Log in to view Number
         </button>
        )}
       </div>
      )}
     </div>
     <div className={'info-container'}>
      <div className={'info-title'}>Clients worked with</div>
      <div className={'clients-wrapper'}>
       {agencyDetails?.majorClients?.split(',')?.map((client, index) => {
        return <Chip key={createKey(index, client)} title={client} />;
       })}
      </div>
     </div>
     <div className={'service-info-container'}>
      {agencyDetails?.servicesFocus && <div className={'info-title'}>Service Focus</div>}
      {agencyDetails?.servicesFocus &&
       agencyDetails?.servicesFocus?.map((serviceFocus, index) => {
        return (
         <Accordion key={createKey(index, serviceFocus.category)}>
          <AccordionSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1-content"
           id="panel1-header">
           <div className="service-category-project-detail">
            {categoryImages?.map(
             (data, index) =>
              serviceFocus?.category === data?.category && (
               <img key={index} src={data?.icon} alt="icon" />
              )
            )}
            <div className="service-category-title">{serviceFocus?.category}</div>
           </div>
          </AccordionSummary>
          <AccordionDetails>
           <div className={'accordian-summary-wrapper'}>
            {serviceFocus?.services?.map((service, index) => {
             return (
              <div key={createKey(index, service.name)} className={'accordian-service-wrapper'}>
               <div className={'service-name'}>{service.name}</div>
               <div className={'service-percentage-wrapper'}>
                <div>{service.percentage}</div>
                <BorderLinearProgress
                 variant="determinate"
                 value={Number(service.percentage.replace('%', ''))}
                />
               </div>
              </div>
             );
            })}
           </div>
          </AccordionDetails>
         </Accordion>
        );
       })}
     </div>
     <div ref={ratingsRef} className={'ratings-and-reviews-info-container'}>
      <div className={'info-title'}>Rating & Reviews</div>
      {agencyDetails?.ratings_and_reviews?.length > 0 && (
       <div className={'average-rating-container'}>
        <div className={'ratings-container'}>
         <div className={'ratings-sub-wrapper-1'}>
          <div>
           <img src={ratingsStar} alt="rating star" />
          </div>
          <div className={'ratings-number-wrapper'}>
           <div className={'rating'}>{agencyDetails?.techreviewerRating}</div>
           &nbsp;/&nbsp;<div>5</div>
          </div>
         </div>
         <div className={'ratings-sub-wrapper-2'}>
          <div>&#40;Based on {agencyDetails?.ratings_and_reviews?.length} ratings&#41;</div>
         </div>
        </div>
        <div className={'average-rating-text'}>Average rating based on Google reviews</div>
       </div>
      )}
      {agencyDetails?.ratings_and_reviews?.length > 0 && (
       <div id="ratings-and-reviews" className={'reviews'}>
        <Reviews reviews={agencyDetails?.ratings_and_reviews?.slice(0, noOfReviewsToLoad)} />
       </div>
      )}
      {agencyDetails?.ratings_and_reviews?.length === 0 && (
       <div className="no-ratings-container">No Reviews Found</div>
      )}
      {agencyDetails?.ratings_and_reviews?.length !== 0 &&
       noOfReviewsToLoad !== agencyDetails?.ratings_and_reviews?.length && (
        <div className="load-more-btn-container">
         <button className={'load-more-btn'} onClick={() => loadMoreReviews()}>
          <div>Load more</div>
          <img alt="down arrow" src={arrowDown} />
         </button>
        </div>
       )}
     </div>
    </div>
   )}
  </div>
 );
};
export default AgencyDetails;

import React, { useEffect, useRef } from 'react';
import 'Styles/LandingPage/landingSection.css';
import PortfolioReview from 'Assets/Landing/portfolioReview.svg';
import SkillsValidation from 'Assets/Landing/skillsValidation.svg';
import ExpertiseValidation from 'Assets/Landing/expertiseValidation.svg';
import MarketplaceListing from 'Assets/Landing/marketplaceListing.svg';
import { onImageError } from 'Utilities/helpers';
import stroke1 from 'Assets/Landing/stroke_1.png';
import stroke2 from 'Assets/Landing/stroke_2.png';
import stroke3 from 'Assets/Landing/stroke_3.png';

const LandingTop1PercentAgencies = () => {
 //********************************************************Constants***************************************************//

 const contents = [
  {
   image: PortfolioReview,
   title: 'Agency portfolio review',
   percentage: '100%',
   description: 'manual screening of previous work'
  },
  {
   image: SkillsValidation,
   title: 'Reputation & soft skills validation',
   percentage: 'Only 10%',
   description: 'pass our vetting for client communication and reliability'
  },
  {
   image: ExpertiseValidation,
   title: 'Technical expertise validation',
   percentage: 'Only 5%',
   description: 'make it past our technical screening'
  },
  {
   image: MarketplaceListing,
   title: 'Final marketplace listing',
   percentage: 'Only 1%',
   description: 'of agencies meet our standards for Tokai listing'
  }
 ];

 const top1BoxRef = useRef(null);

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  if (top1BoxRef.current) {
   const top1Box = top1BoxRef.current;
   const style = document.createElement('style');
   style.innerHTML = `
       .top1-box::before {
         content: ''; 
         position: absolute; 
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         background-image: url(${stroke3}),
           url(${stroke2}), 
           url(${stroke1});
         background-repeat: no-repeat;
         background-position: left 0px bottom 15%, left 0px bottom 57%, left 0px bottom 100%;
         background-size: contain;
         opacity: 0.1;
         z-index: -1; 
       }
     `;
   document.head.appendChild(style);

   top1Box.style.position = 'relative';
  }
 }, []);

 //********************************************************html***************************************************//

 return (
  <div ref={top1BoxRef} className="top1-box">
   <div className="top1-wrapper">
    <div className="top1-main-content">
     <div className="top1-heading">
      <div className="top1-main-title">Work with Confidence</div>
      <div className="top1-title-description">
       Top 1% agencies — <span>We handpick</span> only the best through a 4-step process
      </div>
     </div>
     <div className="top1-card-container">
      <div className="top1-card-wrapper">
       {contents?.map((data, index) => {
        return (
         <div className="top1-details" key={index}>
          <div className="top1-details-image">
           <img
            onError={onImageError}
            src={data?.image}
            alt="handpicked-step"
            width="100%"
            height="100%"
           />
          </div>
          <div className="top1-divisions">
           <div className="top1-title">{data?.title}</div>
           <div className="top1-percentage">{data?.percentage}</div>
           <div className="top1-descriotion">{data?.description}</div>
          </div>
         </div>
        );
       })}
      </div>
     </div>
    </div>
    <div className="connect-agencies-portion">
     <div className="connect-agencies-wrapper">
      <div className="connect-agencies-title">
       Connect with the <span>World's Best Agencies</span>
      </div>
      <div className="connect-agencies-description">
       With 7+ years of experience, our agencies from Europe, LatAm, and Asia are ready to help you
       tackle your biggest development challenges.
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default LandingTop1PercentAgencies;

import React, { useEffect, useRef, useState } from 'react';
import 'Styles/mainContent.css';
import noDataImg from 'Assets/noData.svg';
import { createKey } from 'Utilities/helpers';
import filter from 'Assets/filters.svg';
import BreadCrumbs from '../CommonComponents/breadcrumbs';
import Card from './card';
import FilterModal from './responsiveFilter';
import urls from 'Config/url';
import cookie from 'react-cookies';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Verification from '../ProjectBriefPage/verificationModal';
import arrowright from 'Assets/arrowRight.svg';
import BasicModal from '../CommonComponents/modal';
import ProjectDetailsForm from '../ProjectBriefPage/projectDetails';

const MainContent = ({
 cardInfo,
 dropdownMenus,
 loading,
 setUserId,
 comapanyNumber,
 setPageNumber,
 setFilterOptions,
 mainfilter,
 filterOption
}) => {
 //********************************************************Constants***************************************************//

 let location = useLocation();
 const navigate = useNavigate();
 const bodyHeaderRef = useRef();
 const subHeaderRef = useRef();
 const [subHeaderHeight, setSubHeaderHeight] = useState(null);
 const [openfilter, setOpenfilter] = useState(false);
 const [selectedOptions, setSelectedOptions] = useState({});
 const [showloader, setShowLoader] = useState(true);
 const { slug } = useParams();
 const [categoryName, setCategoryName] = useState('');
 const top1BoxRef = useRef(null);
 const user = cookie.load('access_token');

 const breadcrumbs = [
  {
   label: localStorage?.getItem('tab') === 'topAgency' ? 'Top Agencies' : 'Home',
   url: localStorage?.getItem('tab') === 'topAgency' ? urls?.URL_TOPAGENCY : urls.URL_HOME
  },
  {
   label: categoryName || 'Loading...'
  }
 ];

 const [projectDetailModalOpen, setProjectDetailModalOpen] = useState(false);
 const [openPopUp, setOpenPopUp] = useState(false);
 const [authType, setAuthType] = useState('signin');
 const [verificationModalOpen, setVerificationModalOpen] = useState(false);
 const projectDetailsModalProps = {
  Content: ProjectDetailsForm,
  setOpen: setProjectDetailModalOpen,
  open: projectDetailModalOpen,
  setVerificationModalOpen,
  setOpenPopUp,
  setAuthType
 };
 const verificationModalProps = {
  Content: Verification,
  setOpen: setVerificationModalOpen,
  open: verificationModalOpen,
  setOpenPopUp,
  setAuthType
 };

 //********************************************************UseEffects***************************************************//

 useEffect(() => {
  setSelectedOptions({});
 }, [categoryName]);

 //Extracting the category name from slug
 useEffect(() => {
  const formattedCompanyName = slug
   .split('-')
   ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
   .join(' ');
  setCategoryName(formattedCompanyName);
 }, [slug]);

 //Setting loader untill receiving response
 useEffect(() => {
  if (comapanyNumber === 0) {
   const loaderTimeout = setTimeout(() => {
    setShowLoader(false);
   }, 3000);
   return () => clearTimeout(loaderTimeout);
  }
 }, [comapanyNumber]);

 useEffect(() => {
  if (subHeaderRef?.current) {
   setSubHeaderHeight(subHeaderRef?.current?.offsetHeight);
  }
 }, []);

 if (location?.state?.category === null) return null;

 const handleClear = () => {
  setFilterOptions({});
  window.history.replaceState({}, '');
 };

 //If it is not logged in redirect to the login page else the project brief modal opens
 const handleAuthentication = () => {
  if (user) {
   setProjectDetailModalOpen(true);
  } else {
   navigate(urls?.URL_AUTH);
  }
 };

 //********************************************************html***************************************************//

 return (
  <div data-testid="main-content" className="agencylist-body-container">
   <div ref={bodyHeaderRef} className="body-header-wrapper-container">
    <div className="body-header-content-container">
     <BreadCrumbs breadcrumbs={breadcrumbs} />
     <div className="category-name-title">{categoryName}</div>
    </div>
   </div>
   {!comapanyNumber && showloader && (
    <div className={'loader'}>
     <div className={'loader-progress'}></div>
    </div>
   )}

   <div id="content_body" className="list-container">
    <div
     data-testid="card-list"
     style={{
      opacity: loading ? '0.1' : '1',
      height: `calc(100% - ${subHeaderHeight + 32}px)`
     }}
     className="cards-container">
     <div className="company-total-number">
      <div className="company-number">{comapanyNumber} Companies found</div>
      <div className="responsive-clear-filter-container">
       {filterOption && Object.values(filterOption).some((value) => value?.length > 0) && (
        <div className="responsive-clear-button" onClick={() => handleClear()}>
         Clear
        </div>
       )}
       <div className="responsive-filter-container">
        <div onClick={() => setOpenfilter(!openfilter)} className="responsive-filter">
         <div className="filter-image">
          <img alt="filter" width="100%" height="100%" src={filter} />
         </div>
         <div className="filter-text">Filter</div>
        </div>
       </div>
      </div>
     </div>
     {openfilter && (
      <FilterModal
       dropdownMenus={dropdownMenus}
       open={openfilter}
       setOpen={setOpenfilter}
       setPageNumber={setPageNumber}
       setFilterOptions={setFilterOptions}
       selectedOptions={selectedOptions}
       setSelectedOptions={setSelectedOptions}
       filterOption={filterOption}
      />
     )}
     {comapanyNumber !== 0 &&
      cardInfo?.length > 0 &&
      cardInfo?.map((card, index) => {
       return (
        <Card
         key={createKey(index, card?.companyName)}
         agencyData={card}
         setUserId={setUserId}
         mainfilter={mainfilter}
        />
       );
      })}
     {comapanyNumber === 0 && !showloader && (
      <div data-testid="no-data-img" className="nodata-container">
       <div className="nodata-img-container">
        <img alt="no data" width="100%" height="100%" src={noDataImg} />
        <div className="nodata-text">No companied found based on your interest</div>
       </div>
       <div
        ref={top1BoxRef}
        onClick={() => handleAuthentication()}
        className={['navigation-container']}>
        <div className={'navigation-content'}>
         Tell us your requirements and we will help you getting an agency{' '}
         <span>within an hour</span>
        </div>
        <div className={['arrow-image']}>
         <img className={'searchImage'} alt="lens" src={arrowright} width="100%" height="100%" />
        </div>
       </div>
      </div>
     )}
    </div>
   </div>
   {verificationModalOpen && <BasicModal {...verificationModalProps} />}
   {projectDetailModalOpen && <BasicModal {...projectDetailsModalProps} />}
  </div>
 );
};

export default MainContent;

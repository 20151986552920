import React, { useContext } from 'react';
import 'Styles/authPopUp.css';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import AuthForm from './authForm';
import verified from 'Assets/verified.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import RefContext from 'Utilities/refContext';
import ResetPassword from './resetPassword';

const AuthPopUp = ({ authType, setOpen, setForgotPasswordPopup }) => {
 //********************************************************Constants***************************************************//

 const [message, setMessage] = useState(null);
 let navigate = useNavigate();
 let location = useLocation();

 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { login } = actions;

 //********************************************************Function***************************************************//

 // Login api integration
 const handlelogin = async (data, sso = false) => {
  if (sso) {
   login({ token: data }, navigate, location);
  }
 };

 //********************************************************html***************************************************//

 return (
  <div className={'auth-popup-container'}>
   {location?.pathname === '/reset-password' ? (
    <ResetPassword location={location} />
   ) : (
    <div className={'content-container'}>
     <div className={'line-container'}>
      <div className={'line-width'}></div>
      <div className={'line2-width'}></div>
     </div>
     <div className={'login-title-container'}>
      <div className={'login-title'}>{authType === 'signin' ? 'Log in' : 'Sign Up'}</div>
      <div className={'about-content'}>Delivering trusted software development agencies to you</div>
      <div className={'verified-content'}>
       <div className={'verified'}>20,500+ Verified agencies</div>
       <div className={'verified-image'}>
        <img alt="lens" src={verified} width="100%" height="100%" />
       </div>
      </div>
     </div>
     <div className={'body-container'}>
      <div className={'sso-container'}>
       <div className={'sso-btn'}>
        <GoogleOAuthProvider
         clientId={'652200643583-m5i16i87b7r2mc6nkile9baibbbj0iou.apps.googleusercontent.com'}>
         <GoogleLogin
          onSuccess={(credentialResponse) => {
           handlelogin(credentialResponse?.credential, true);
          }}
          onError={() => {
           console.log('Login Failed');
          }}
          text="continue_with"
         />
        </GoogleOAuthProvider>
       </div>
      </div>
      <div className={'continue-with-container'}>
       <div className={'line'}></div>
       <div className={'sub-title'}>or continue with email</div>
       <div className={'line'}></div>
      </div>
      <AuthForm
       authType={authType}
       setForgotPasswordPopup={setForgotPasswordPopup}
       setMessage={setMessage}
       message={message}
       setOpen={setOpen}
       login={login}
      />
     </div>
    </div>
   )}
  </div>
 );
};

export default AuthPopUp;

// src/Config/url.js
const urls = {
 URL_HOME: '/home',
 URL_LANDING: '/',
 URL_PROJECTBRIEF: '/project-detail',
 URL_TOPAGENCY: '/top-agency',
 URL_AGENCY: '/slug/',
 URL_PROFILE: '/profile/',
 URL_AUTH: '/login',
 routeTo: (url, history, params) => {
  history.push(getUrl(url, params));
 }
};

export default urls;

import cloneDeep from 'lodash/cloneDeep';
import { setNamespace, setApiError } from 'Utilities/helpers';
import Network from 'Utilities/network';

const namespace = 'agency';
const createAction = setNamespace(namespace);
const nw = new Network();

// STORE
const initialState = {
 apiError: null,
 agency: null
};

// ACTIONS

const ASSIGN_TO_AGENCY_STORE = createAction('ASSIGN_TO_AGENCY_STORE');
const RESET_AGENCY_STORE = createAction('RESET_AGENCY_STORE');

const assignToAgencyStore = (type, payload) => ({
 type: ASSIGN_TO_AGENCY_STORE,
 meta: {
  type,
  payload
 }
});

const resetAgencyStore = () => (dispatch) => {
 dispatch({
  type: RESET_AGENCY_STORE,
  meta: {
   payload: null
  }
 });
};

// METHODS
const getAgency = (id) => (dispatch) => {
 dispatch(assignToAgencyStore('agency', null));
 return nw
  .apiWithPath('agency_details', [id])
  .get()
  .then((response) => {
   dispatch(assignToAgencyStore('agency', response?.data));
  })
  .catch((error) => {
   setApiError(dispatch, assignToAgencyStore, error);
  });
};

//Use If need DB json
// const getAllRequetUser = () => (dispatch) => {
//   dispatch(assignToAgencyStore("get_Posts", null));
//   return nw
//     .api("get_Posts")
//     .get()
//     .then((response) => {
//       dispatch(assignToAgencyStore("testData", response?.data));
//     }).catch((error) => {
//       setApiError(dispatch, assignToAgencyStore, error);
//     });
// };

// Routing

// Reducers
const dashboardReducer = (state = initialState, action) => {
 const localState = cloneDeep(state);

 switch (action.type) {
  case ASSIGN_TO_AGENCY_STORE:
   localState[action.meta.type] = action.meta.payload;
   return { ...localState };
  case RESET_AGENCY_STORE:
   return initialState;
  default:
   return localState;
 }
};

export default {
 namespace,
 store: initialState,
 reducer: dashboardReducer,
 creators: {
  assignToAgencyStore,
  resetAgencyStore,
  getAgency
 }
};

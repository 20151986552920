import React, { useState } from 'react';
import 'Styles/button.css';
import EastIcon from '@mui/icons-material/East';

const Button = ({
 content,
 className,
 handleClick,
 gap,
 width,
 disable,
 size,
 background,
 textColor,
 hoverType
}) => {
 //********************************************************Constants***************************************************//

 const [isHovered, setIsHovered] = useState(false);

 const classNameStr = disable ? `${className} ${'disable-button'}` : `${className}`;
 let height;
 switch (size) {
  case 'small':
   height = `${24}px`;
   break;
  case 'medium':
   height = `${32}px`;
   break;
  default:
   height = `${40}px`;
   break;
 }

 const hoverClass = hoverType ? `hover-${hoverType}` : '';

 const iconColor = isHovered ? '#FFFFFF' : textColor === '#FFFFFF' ? '#FFFFFF' : 'black';

 //********************************************************html***************************************************//

 return (
  <button
   style={{
    width,
    backgroundColor: background,
    color: textColor,
    cursor: content === 'Book a call' ? 'default' : 'pointer',
    fontSize: content === 'Book a call' ? '14px' : ''
   }}
   className={`${classNameStr} ${hoverClass}`}
   onClick={handleClick}
   onMouseEnter={() => setIsHovered(true)}
   onMouseLeave={() => setIsHovered(false)}>
   <div className={'button-content-wrapper'} style={{ gap, height }}>
    {content}
    {content === 'Book a call' && (
     <>
      <br />
      COMING SOON
     </>
    )}
   </div>
   {content === 'Hire with Confidence' && <EastIcon fontSize="small" sx={{ color: iconColor }} />}
  </button>
 );
};

//********************************************************Types of buttons***************************************************//

// eslint-disable-next-line react/display-name
Button.Primary = ({
 content,
 onClick,
 gap,
 width,
 disable,
 size,
 bgcolor,
 textColor,
 hoverType
}) => {
 return (
  <Button
   disable={disable}
   width={width}
   className={'primary-button'}
   handleClick={onClick}
   content={content}
   gap={gap}
   size={size}
   background={bgcolor}
   textColor={textColor}
   hoverType={hoverType}
  />
 );
};

// eslint-disable-next-line react/display-name
Button.Secondary = ({ content, onClick, gap, width, disable, size }) => {
 return (
  <Button
   disable={disable}
   width={width}
   className={'secondary-button'}
   handleClick={onClick}
   content={content}
   gap={gap}
   size={size}
  />
 );
};

Button.Common = ({ content, onClick, gap, width, disable, size }) => {
 return (
  <Button
   disable={disable}
   width={width}
   className={'common-button'}
   handleClick={onClick}
   content={content}
   gap={gap}
   size={size}
  />
 );
};

export default Button;

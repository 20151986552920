import React, { useEffect, useRef, useState } from 'react';
import 'Styles/homeContent.css';
import urls from 'Config/url';
import MainContentHeader from '../CommonComponents/mainContentHeader';
import verified from 'Assets/verified.svg';
import trophy from 'Assets/blueTrophy.svg';
import arrowright from 'Assets/arrowRight.svg';
import { useNavigate } from 'react-router-dom';
import { createKey } from 'Utilities/helpers';
import activecode from 'Assets/Categories/activeCodeimage.svg';
import activeservicelines from 'Assets/Categories/activeServicelines.svg';
import activedomainfocus from 'Assets/Categories/activeDomainfocus.svg';
import activecms from 'Assets/Categories/activeCmssolution.svg';
import activeprogram from 'Assets/Categories/activeDeviceMobile.svg';
import activeframework from 'Assets/Categories/activeFramework.svg';
import activesoftware from 'Assets/Categories/activeCustomsoftware.svg';
import activeai from 'Assets/Categories/activeAi.svg';
import activemobile from 'Assets/Categories/activeCube.svg';
import activeqa from 'Assets/Categories/activeNote.svg';
import activeitconsultancy from 'Assets/Categories/activeItconsulting.svg';
import activedesign from 'Assets/Categories/activePaintBrush.svg';
import activeecommerce from 'Assets/Categories/activeStorefront.svg';
import activegame from 'Assets/Categories/activeGamedevelopment.svg';
import cookie from 'react-cookies';
import Verification from '../ProjectBriefPage/verificationModal';
import ProjectDetailsForm from '../ProjectBriefPage/projectDetails';
import BasicModal from '../CommonComponents/modal';
import activedevops from 'Assets/Categories/activeUsersFour.svg';
import backgroundBar from 'Assets/backgroundBar.svg';

const HomeContent = ({ topAgency, currentpage, currentFilters }) => {
 //********************************************************Constants***************************************************//

 const user = cookie.load('access_token');
 const navigate = useNavigate();
 const [projectDetailModalOpen, setProjectDetailModalOpen] = useState(false);
 const [openPopUp, setOpenPopUp] = useState(false);
 const [authType, setAuthType] = useState('signin');
 const [verificationModalOpen, setVerificationModalOpen] = useState(false);
 const projectDetailsModalProps = {
  Content: ProjectDetailsForm,
  setOpen: setProjectDetailModalOpen,
  open: projectDetailModalOpen,
  setVerificationModalOpen,
  setOpenPopUp,
  setAuthType
 };
 const verificationModalProps = {
  Content: Verification,
  setOpen: setVerificationModalOpen,
  open: verificationModalOpen,
  setOpenPopUp,
  setAuthType
 };

 const categoryImages = [
  activeservicelines,
  activesoftware,
  activeecommerce,
  activedomainfocus,
  activecode,
  activeframework,
  activecms,
  activedesign,
  activeprogram,
  activemobile,
  activeitconsultancy,
  activeqa,
  activeai,
  activeecommerce,
  activemobile,
  activedevops,
  activegame
 ];

 const top1BoxRef = useRef(null);

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  if (top1BoxRef.current) {
   const top1Box = top1BoxRef.current;
   const style = document.createElement('style');
   style.innerHTML = `
       .navigation-container::before {
         content: ''; 
         position: absolute; 
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         background-image: url(${backgroundBar});
         background-repeat: no-repeat;
         background-position: 100% 96%;
         opacity: .2;
         z-index: 0;
       }
     `;
   document.head.appendChild(style);
   top1Box.style.position = 'relative';
  }
 }, []);

 //********************************************************Functions***************************************************//

 //Redirect to the agencylist list page with category filter
 const handleRoute = (category) => {
  currentpage(1);
  currentFilters({});
  navigate(`/${category?.slug}`, {
   state: { category: category?.category, title: category?.title }
  });
 };

 //If it is not logged in redirect to the login page else the project brief modal opens
 const handleAuthentication = () => {
  if (user) {
   setProjectDetailModalOpen(true);
  } else {
   navigate(urls?.URL_AUTH);
  }
 };

 //********************************************************html***************************************************//

 return (
  <div className={'home-content-layout'}>
   {!topAgency && (
    <div className={'loader'}>
     <div className={'loader-progress'}></div>
    </div>
   )}
   <MainContentHeader topAgency={topAgency} />
   {topAgency && (
    <div className={'home-content-container'}>
     <div className={'category-container'}>
      <div className={'top-service-container'}>
       <div className={'category-content'}>
        <div className={'home-category-title'}>
         Delivering Trusted <span>Software Development Agencies to You</span>
        </div>
        <div className={'categories-verification'}>
         <div className={'category-info'}>
          <div style={{ textDecoration: 'underline' }}>20,500+ Verified information </div>
          <span>on tech companies and their core strengths.</span>
         </div>
         <div className={'verified-image'}>
          <img alt="lens" src={verified} width="100%" height="100%" />
         </div>
        </div>
       </div>
       <div className={'top-service-tag-container'}>
        <div className={'tag-title-container'}>
         <div className={'tag-image'}>
          <img className={'searchImage'} alt="lens" src={trophy} width="100%" height="100%" />
         </div>
         <div className={'tag-title'}>Top services around the world</div>
        </div>
        <div className={'home-info'}>
         We’ve handpicked top companies from across the globe, each specializing in unique
         technologies and solutions.
        </div>
       </div>
       <div className={'service-list'}>
        {topAgency?.topAgencies_category?.map((service, index) => (
         <button
          style={{ color: '#1F1F1F' }}
          key={createKey(index, service?.title)}
          className={'tag-content'}
          onClick={() => handleRoute(service)}>
          {service?.title}
         </button>
        ))}
       </div>
      </div>
      <div
       ref={top1BoxRef}
       onClick={() => handleAuthentication()}
       className={['navigation-container']}>
       <div className={'navigation-content'}>
        Tell us your requirements and we will help you getting an agency <span>within an hour</span>
       </div>
       <div className={['arrow-image']}>
        <img className={'searchImage'} alt="lens" src={arrowright} width="100%" height="100%" />
       </div>
      </div>
      <div className={'categories'}>
       <div className={'one-side-categories-list'}>
        {topAgency?.topAgencies_category_and_services?.map((service, index) => (
         <div key={createKey(index, service)} className={'single-category'}>
          <div className={'category-type-title'}>
           <div className={'tag-image'}>
            <img alt="lens" src={categoryImages[index]} width="100%" height="100%" />
           </div>
           <div className={'tag-title'}>{service?.category}</div>
          </div>
          <div className={'agencylist-container'}>
           {service?.services?.slice(0, 5)?.map((value, index) => (
            <button
             style={{ color: '#1F1F1F' }}
             key={createKey(index, value?.title)}
             className={'tag-content'}
             onClick={() =>
              handleRoute({ category: value?.name, title: value?.title, slug: value?.slug })
             }>
             {value?.title}
            </button>
           ))}
          </div>
         </div>
        ))}
       </div>
      </div>
     </div>
    </div>
   )}
   {verificationModalOpen && <BasicModal {...verificationModalProps} />}
   {projectDetailModalOpen && <BasicModal {...projectDetailsModalProps} />}
  </div>
 );
};

export default HomeContent;

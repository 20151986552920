import React from 'react';

import RefProvider from 'Utilities/refProvider';
import RefErrorBoundary from 'Utilities/refErrorBoundary';
import { formStoreData } from 'Utilities/helpers';
import AgencyListPage from './AgencyList';

const AgencyLists = (props) => {
 const propShape = formStoreData(props, ['agencylist', 'dashboard', 'auth']);
 return (
  <>
   <RefProvider data={propShape}>
    <RefErrorBoundary {...props}>
     <AgencyListPage />
    </RefErrorBoundary>
   </RefProvider>
  </>
 );
};

export default AgencyLists;

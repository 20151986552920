import React, { useContext } from 'react';
import 'Styles/verificationModal.css';
import RefContext from 'Utilities/refContext';
import Button from '../CommonComponents/button';
import verification from 'Assets/verification.svg';
import { useNavigate } from 'react-router-dom';

const Verification = ({ setOpen }) => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store } = ctx;
 const { submited_brief } = store;

 const navigate = useNavigate();

 const formattedServiceType = submited_brief?.serviceType?.replace(/\s+/g, '-').toLowerCase();

 //********************************************************Function***************************************************//

 //Redirecting to the agency list page with the filters which has been selected from the project brief section
 const handleRoute = () => {
  setOpen(false);
  if (submited_brief) {
   navigate(`/top-${formattedServiceType}-companies`, {
    state: {
     title: `Top ${submited_brief?.serviceType} Companies`,
     category: submited_brief?.serviceType,
     filters: {
      MinProjectSize: [submited_brief?.maxBudget],
      Domain: [submited_brief?.projectDomain]
     }
    }
   });
  }
 };

 //********************************************************html***************************************************//

 return (
  <div className={'verification-container'}>
   <div className={'verification-layout'}>
    <div className={'verified-image-content'}>
     <img alt="info" src={verification} width="132px" height="132px" />
    </div>
    <div className={'waiting-message'}>
     We got your brief & reach you out
     <div>within an hour</div>
    </div>
   </div>
   <div className={'skip-buttons'}>
    <div className={'button'} onClick={() => setOpen(false)}>
     <Button.Secondary content="Close" />
    </div>
    <div className={'button'} onClick={() => handleRoute()}>
     <Button.Primary bgcolor="var(--primary-color)" content="View suggestion" />
    </div>
   </div>
  </div>
 );
};
export default Verification;

import React, { useContext } from 'react';
import 'Styles/index.css';
import Menu from '@mui/material/Menu';
import RefContext from 'Utilities/refContext';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { createKey } from 'Utilities/helpers';
import cookie from 'react-cookies';
import { Notification } from '@contentful/f36-components';
import urls from 'Config/url';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { truncateText } from 'Utilities/helpers.js';
import { Tooltip } from '@mui/material';

const MenuComponent = ({ anchorEl, open, handleClose, listItems }) => {
 //********************************************************Constants***************************************************//

 const navigate = useNavigate();
 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { logout } = actions;
 const { user } = store;

 //********************************************************Function***************************************************//

 // Clearing cookies after logout
 const handleLogout = async () => {
  if (cookie.load('access_token')) {
   handleClose();
   cookie.remove('access_token');
   Notification.setPlacement('top');
   await Notification.success('Logging out');
   setTimeout(() => {
    window?.location?.reload();
   }, 1000);
   logout();
  } else {
   handleClose();
   navigate(urls.URL_AUTH, {
    state: { isLoggin: true }
   });
  }
 };

 //********************************************************html***************************************************//

 return (
  <Menu
   anchorEl={anchorEl}
   id="account-menu"
   open={open}
   onClose={handleClose}
   onClick={handleClose}
   PaperProps={{
    elevation: 0,
    sx: {
     overflow: 'visible',
     minWidth: 200,
     filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
     mt: 1.5,
     '& ul': {
      borderBottom: '3px solid #a9f005',
      borderRadius: 1.3
     },
     '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
     },
     '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
     }
    }
   }}
   transformOrigin={{ horizontal: 'right', vertical: 'top' }}
   anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
   {user && cookie.load('access_token') && (
    <div className="label-details">
     <div className="label-username">
      <Tooltip title={user?.username?.length > 30 ? user?.username : ''} arrow>
       <span>{truncateText(user?.username, 30)}</span>
      </Tooltip>
     </div>
     <div className="label-email">
      <Tooltip title={user?.email?.length > 30 ? user?.email : ''} arrow>
       <span>{truncateText(user?.email, 30)}</span>
      </Tooltip>
     </div>
    </div>
   )}

   {user && cookie.load('access_token') && <Divider />}
   {listItems?.map((item, index) => {
    return (
     <MenuItem key={createKey(index, item.name)} onClick={() => handleLogout()}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      {item.name}
     </MenuItem>
    );
   })}
  </Menu>
 );
};

export default MenuComponent;

import React from 'react';
import 'Styles/card.css';
import { useEffect, useState } from 'react';
import BasicModal from '../CommonComponents/modal';
import ForgotPassword from '../LoginPage/forgotPassword';
import Button from '../CommonComponents/button';
import AuthPopUp from '../LoginPage/authPopUp';
import currency from 'Assets/currency.svg';
import usersthree from 'Assets/usersThree.svg';
import foundation from 'Assets/foundation.svg';
import locationImg from 'Assets/location.svg';
import rating from 'Assets/rating.svg';
import moto from 'Assets/moto.svg';
import { createKey, truncateText } from 'Utilities/helpers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { onImageError } from 'Utilities/helpers';

const Card = ({ agencyData, setUserId, mainfilter }) => {
 //********************************************************Constants***************************************************//

 const [openPopUp, setOpenPopUp] = useState(false);
 const [authType, setAuthType] = useState('signin');
 const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
 const [percentage, setPercentage] = useState(0);
 const [checkTruncate, setCheckTruncate] = useState(false);
 const navigate = useNavigate();
 let location = useLocation();
 const params = useParams();

 const forgotPasswordModalProps = {
  Content: ForgotPassword,
  setOpen: setForgotPasswordPopup,
  open: forgotPasswordPopup,
  setOpenPopUp,
  setAuthType
 };
 const authModalProps = {
  open: openPopUp,
  Content: AuthPopUp,
  authType,
  setAuthType,
  setOpen: setOpenPopUp,
  setForgotPasswordPopup,
  setUserId
 };

 const keyInformation = [
  {
   images: currency,
   content: agencyData?.hourlyRate
  },
  {
   images: usersthree,
   content: agencyData?.employees
  },
  {
   images: foundation,
   content: `Since ${agencyData?.yearOfFoundation}`
  },
  {
   images: locationImg,
   content: agencyData?.locations?.[0]?.countryAndCity
  }
 ];

 //********************************************************UseEffects***************************************************//

 //Finds the percentage of the particular category from servicefocus data
 useEffect(() => {
  agencyData?.servicesFocus?.forEach((element) => {
   if (element?.category?.toLowerCase() === mainfilter?.category?.toLowerCase()) {
    const percent = element?.services?.[0]?.percentage;
    setPercentage(percent);
   }
  });
  if (!percentage) {
   agencyData?.servicesFocus?.forEach((element) => {
    element?.services?.forEach((value) => {
     if (value?.name?.toLowerCase() === mainfilter?.category?.toLowerCase()) {
      const percent = value?.percentage;
      setPercentage(percent);
     }
    });
   });
  }
 }, []);

 //********************************************************Functions***************************************************//

 // when the agency card is clicked it move to the profile page of that agency
 const handleroute = () => {
  navigate(`/${params?.slug}/${agencyData?.slug}`, {
   state: { name: location?.state, id: agencyData?.id }
  });
 };

 //It opens the particular company website page in new tab
 const websiteLink = (link) => {
  window.open(link, '_blank');
 };

 //********************************************************html***************************************************//

 return (
  <>
   <BasicModal {...(forgotPasswordPopup ? forgotPasswordModalProps : authModalProps)} />
   <div data-testid="test-card" className={'card-container'}>
    <div className={'responsive-line'}></div>
    <div className={'card-content'}>
     <div className={'card-left-container'} onClick={() => handleroute()}>
      <div className={'box'}>
       {agencyData?.logoUrl && (
        <img
         onError={onImageError}
         alt="info"
         src={agencyData?.logoUrl}
         width="100%"
         height="100%"
        />
       )}
      </div>
      <div className={'company-name'}>{agencyData?.companyName}</div>
     </div>
     <div className={'company-rating-container'}>
      <div className={'rating-star'}>
       <img alt="star" src={rating} width="100%" height="100%" />
      </div>
      <div className={'rating-content'}>
       {agencyData?.techreviewerRating} <span>/ 5</span>
      </div>
     </div>
    </div>
    {agencyData?.motto && (
     <div className={'company-rating'}>
      <div className={'rating-star'}>
       <img alt="star" src={moto} width="100%" height="100%" />
      </div>
      <div className={'moto'}>{agencyData?.motto}</div>
     </div>
    )}
    <div className={'description-company'}>
     {checkTruncate ? (
      <span dangerouslySetInnerHTML={{ __html: agencyData?.description }} />
     ) : (
      <span
       dangerouslySetInnerHTML={{
        __html: truncateText(agencyData?.description, 150, 'description')
       }}
      />
     )}
     {agencyData?.description?.length > 150 && !checkTruncate && (
      <span
       onClick={() => {
        setCheckTruncate(true);
       }}>
       ...see more
      </span>
     )}
     {checkTruncate && (
      <span
       onClick={() => {
        setCheckTruncate(false);
       }}>
       see less
      </span>
     )}
    </div>
    <div className={'tags'}>
     <div className={'tag-name'}>{mainfilter?.category}</div>
     <div className={'tag-percent'}>{percentage || '0%'}</div>
    </div>
    <div className={'key-info-container'}>
     {keyInformation?.map((keyValues, index) => (
      <React.Fragment key={createKey(index, keyValues?.content)}>
       <div className={'company-rating-container'}>
        <div className={'key-image'}>
         <img alt="star" src={keyValues?.images} width="100%" height="100%" />
        </div>
        <div className={'key-info'}>{keyValues?.content}</div>
       </div>
       {index != 3 && <div className={'border-seperator'}></div>}
      </React.Fragment>
     ))}
    </div>
    <div className={'responsive-key-info-container'}>
     {keyInformation?.map((keyValues, index) => (
      <div key={createKey(index, keyValues?.content)} className={'responsive-key-info'}>
       <div className={'key-image'}>
        <img alt="star" src={keyValues?.images} width="100%" height="100%" />
       </div>
       <div className={'key-info'}>{keyValues?.content}</div>
      </div>
     ))}
    </div>
    <div className={'button-container'}>
     <div style={{ width: '50%' }} onClick={() => websiteLink(agencyData?.websiteUrl)}>
      <Button.Secondary width="100%" content="Visit Website" size={'medium'} />
     </div>
     <div onClick={handleroute} style={{ width: '50%' }}>
      <Button.Primary
       bgcolor="var(--primary-color)"
       content="View profile"
       width={'100%'}
       size={'medium'}
       disable={false}
      />
     </div>
    </div>
    <div className={'card-end-line'}></div>
   </div>
  </>
 );
};

export default Card;

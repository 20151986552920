import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import 'Styles/modal.css';
import close from 'Assets/close.svg';
import RefContext from 'Utilities/refContext';

const BasicModal = ({
 Content,
 open,
 setOpen,
 authType,
 setAuthType,
 setForgotPasswordPopup,
 setVerificationModalOpen
}) => {
 //********************************************************Constants***************************************************//

 const [windowWidth, setWindowWidth] = useState(0);
 const ctx = useContext(RefContext);
 const { actions } = ctx;
 const { setModalOpen } = actions;
 const style = {
  position: 'absolute',
  top: windowWidth > 991 ? '50%' : '54%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: windowWidth < 992 ? 'calc(100% - 32px)' : 400,
  bgcolor: '#FFFFFF',
  height: windowWidth < 992 ? 'calc(100% - 133px)' : 'fit-content',
  border: '1px solid #DFE1E6',
  borderRadius: windowWidth < 992 ? '0px' : '12px',
  boxShadow: '0px 3px 6px 0px #091E4208',
  p: '40px 16px 25px 16px',
  outline: 'none',
  overflow: 'scroll'
 };

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  const handleResize = () => {
   setWindowWidth(window.innerWidth);
  };

  handleResize();
  window.addEventListener('resize', handleResize);

  return () => {
   window.removeEventListener('resize', handleResize);
  };
 }, []);

 useEffect(() => {
  setModalOpen(open);
 }, [open]);

 //********************************************************Functions***************************************************//

 //Close the modal
 const handleClose = (event) => {
  event.stopPropagation();
  setModalOpen(false);
  setOpen(false);
 };

 const handleModalClick = (event) => {
  event.stopPropagation();
 };

 //********************************************************html***************************************************//

 return (
  <Modal
   open={open}
   onClose={handleClose}
   aria-labelledby="modal-modal-title"
   aria-describedby="modal-modal-description"
   style={{
    backdropFilter: windowWidth > 991 ? 'blur(4px)' : ''
   }}>
   <Box sx={style}>
    <div className={'modal'} onClick={handleModalClick}>
     <div className={'close'}>
      <button onClick={(event) => handleClose(event)} className={'close-container-modal'}>
       <div className={'close-icon'}>
        <img alt="close" src={close} width={13} height={13} />
       </div>
      </button>
     </div>
     <div className={'modal-content'}>
      <Content
       authType={authType}
       setOpen={setOpen}
       setAuthType={setAuthType}
       setForgotPasswordPopup={setForgotPasswordPopup}
       setVerificationModalOpen={setVerificationModalOpen}
      />
     </div>
    </div>
   </Box>
  </Modal>
 );
};

export default BasicModal;

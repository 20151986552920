import React, { useRef, useState, useEffect } from 'react';
import 'Styles/homeContent.css';
import ResponsiveHeader from 'Components/UI/CommonComponents/responsiveHeader';
import search from 'Assets/search.svg';
import { createKey } from 'Utilities/helpers';
import { useNavigate } from 'react-router-dom';

const MainContentHeader = ({ topAgency }) => {
 //********************************************************Constants***************************************************//

 const [searchInput, setSearchInput] = useState('');
 const [searchItem, setSearchItem] = useState([]);
 const [selectedIndex, setSelectedIndex] = useState(-1);
 const dropdown = useRef(null);
 const navigate = useNavigate();

 //********************************************************UseEffect***************************************************//

 // Update search items when input changes
 useEffect(() => {
  const filteredCategories = topAgency?.topAgencies_category?.filter((category) =>
   category?.title?.toLowerCase().includes(searchInput?.toLowerCase())
  );
  setSearchItem(filteredCategories);
 }, [searchInput, topAgency]);

 // Close the dropdown when clicking outside
 useEffect(() => {
  const handleOutSideClick = (event) => {
   if (!dropdown.current?.contains(event.target)) {
    setSearchInput('');
    setSelectedIndex(-1);
   }
  };
  const handleEscKeyPress = (event) => {
   if (event.key === 'Escape') {
    setSearchInput('');
    setSelectedIndex(-1);
   }
  };
  window.addEventListener('mousedown', handleOutSideClick);
  window.addEventListener('keydown', handleEscKeyPress);

  return () => {
   window.removeEventListener('mousedown', handleOutSideClick);
   window.addEventListener('keydown', handleEscKeyPress);
  };
 }, []);

 // Scroll the selected option into view when selectedIndex changes
 useEffect(() => {
  if (selectedIndex >= 0 && dropdown.current) {
   const selectedButton = dropdown.current.querySelectorAll('.dropdown-value')[selectedIndex];
   if (selectedButton) {
    selectedButton.scrollIntoView({
     behavior: 'smooth',
     block: 'center'
    });
   }
  }
 }, [selectedIndex]);

 //********************************************************Functions***************************************************//

 // Navigate to selected category
 const handleRoute = (category) => {
  setSearchInput('');
  setSelectedIndex(-1);
  navigate(`/${category?.slug}`, {
   state: { category: category?.category, title: category?.title }
  });
  window?.location?.reload();
 };

 // Handle search input change
 const handleSearch = (event) => {
  setSearchInput(event.target.value);
 };

 //********************************************************html***************************************************//

 return (
  <>
   <div className="search-bar-container">
    <div className="searchBar">
     <div className="searchImageWrapper">
      <img className="searchImg" alt="lens" src={search} width="100%" height="100%" />
     </div>
     <input
      className="searchBarInput"
      placeholder="Search by software service type. . ."
      value={searchInput}
      onChange={handleSearch}
     />
    </div>
    {searchInput?.length > 0 && (
     <div ref={dropdown} className="searchbar-dropdown-container">
      <div className="dropdown-content">
       {searchItem?.map((value, index) => (
        <button
         key={createKey(value?.title, index)}
         className="dropdown-value"
         style={{
          textDecoration: selectedIndex === index ? 'underline' : 'none'
         }}
         onClick={() => handleRoute(value)}>
         {value?.title}
        </button>
       ))}
       {searchItem?.length === 0 && <div className="searchBarInput">No data found</div>}
      </div>
     </div>
    )}
   </div>
   <ResponsiveHeader setSearchInput={setSearchInput} searchInput={searchInput} />
  </>
 );
};

export default MainContentHeader;

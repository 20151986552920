/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState, useContext } from 'react';
import 'Styles/index.css';
import RefContext from 'Utilities/refContext';
import AuthPopUp from 'Components/UI/LoginPage/authPopUp';
import BasicModal from 'Components/UI/CommonComponents/modal';
import ForgotPassword from 'Components/UI/LoginPage/forgotPassword';
import ResponsiveHeader from 'Components/UI/CommonComponents/responsiveHeader';
import cookie from 'react-cookies';
import logo from 'Assets/logo.svg';
import titleImage from 'Assets/title.svg';
import urls from 'Config/url';
import verified from 'Assets/verified.svg';
import grapicImage from 'Assets/graphicElement.png';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function LoginPage() {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { actions } = ctx;
 const { login } = actions;

 const acctoken = cookie.load('access_token');
 const navigate = useNavigate();
 const [authType, setAuthType] = useState('signin');
 const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
 const [searchInput, setSearchInput] = useState('');

 const forgotPasswordModalProps = {
  Content: ForgotPassword,
  setOpen: setForgotPasswordPopup,
  open: forgotPasswordPopup,
  setAuthType
 };

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  if (acctoken) {
   if (location.pathname !== urls?.URL_PROJECTBRIEF) {
    navigate(urls?.URL_PROJECTBRIEF);
   } else {
    navigate(urls?.URL_HOME);
   }
  }
 }, []);

 //Navigate to the landing page if it is not logged in else navigate to the home page
 const authHandle = () => {
  if (acctoken) {
   navigate(urls?.URL_HOME);
  } else {
   navigate(urls?.URL_LANDING);
  }
 };

 //********************************************************html***************************************************//

 return (
  <div id="page-container" className={'page-container'}>
   {location?.pathname === '/reset-password' ? (
    <Helmet>
     <title>{`Tokai | ResetPassword`}</title>
     <meta name="robots" content="noindex,nofollow" />
     <meta property="og:site_name" content={'Tokai'} />
    </Helmet>
   ) : (
    <Helmet>
     <title>{`Tokai | Login`}</title>
     <meta name="robots" content="noindex,nofollow" />
     <meta property="og:site_name" content={'Tokai'} />
    </Helmet>
   )}
   <BasicModal {...forgotPasswordModalProps} />
   <div className={'landing-page-container'}>
    <div className={'logo-container'}>
     <div onClick={() => authHandle()} className={'logo-content'}>
      <div className={'logo'}>
       <img alt="rect" src={logo} width="100%" height="100%" />
      </div>
      <div className={'title'}>
       <img alt="rect" src={titleImage} width="68px" height="40px" />
      </div>
     </div>
    </div>
    <ResponsiveHeader setSearchInput={setSearchInput} searchInput={searchInput} />

    <div className={'home-page-layout'}>
     <div className={'left-container'}>
      <div className={'left-content'}>
       <div onClick={() => authHandle()} className={'logo-content'}>
        <div className={'logo'}>
         <img alt="rect" src={logo} width="100%" height="100%" />
        </div>
        <div className={'title'}>
         <img alt="rect" src={titleImage} width="68px" height="40px" />
        </div>
       </div>
       <div className={'category-title'}>
        Delivering Trusted <span>Software Development Agencies to You</span>
       </div>
       <div className={'project-category-info'}>
        <div style={{ textDecoration: 'underline' }}>20,500+ Verified information </div>
        <div className={'verified-image'}>
         <img alt="lens" src={verified} width="100%" height="100%" />
        </div>
       </div>
      </div>
      <div className={'element-image'}>
       <img alt="rect" src={grapicImage} width="324px" height="260px" />
      </div>
     </div>
     <AuthPopUp
      authType={authType}
      setAuthType={setAuthType}
      setForgotPasswordPopup={setForgotPasswordPopup}
      login={login}
     />
    </div>
   </div>
  </div>
 );
}
export default LoginPage;

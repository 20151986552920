import React from 'react';
import 'Styles/LandingPage/landingSection.css';
import ExpertiseSkillsCards from './expertiseSkillsCards';

const LandingHireByExpertiseAndSkills = () => {
 return (
  <div className='experts-and-skills'>
   <ExpertiseSkillsCards pageName="expertise" />
   <ExpertiseSkillsCards pageName="skills" />
  </div>
 );
};

export default LandingHireByExpertiseAndSkills;

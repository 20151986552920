import React, { useState } from 'react';
import urls from 'Config/url';
import home from 'Assets/home.svg';
import activehome from 'Assets/activeHome.svg';
import office from 'Assets/BuildingOffice.svg';
import activeOffice from 'Assets/activeBuildingOffice.svg';
import forum from 'Assets/forum.svg';
import profile from 'Assets/profile.svg'; 

export const tabs = [
 {
  title: 'Home',
  path: urls.URL_HOME,
  isActive: (url) => url === urls.URL_HOME,
  image: home,
  activeImage: activehome
 },
 {
  title: 'Top Agencies',
  path: urls.URL_TOPAGENCY,
  isActive: (url) => url === urls.URL_TOPAGENCY,
  image: office,
  activeImage: activeOffice
 },
 {
  title: 'Forum',
  path: '',
  isActive: (url) => url === '',
  image: forum,
  activeImage: forum
 },
 ...(window?.innerWidth < 992
  ? [
     {
      title: 'Profile',
      isActive: (url) => url === urls.URL_PROFILE,
      image: profile,
      activeImage: profile
     }
    ]
  : [])
];

export const headerHeight = 77;

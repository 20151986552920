import React from 'react';
import RefProvider from 'Utilities/refProvider';
import RefErrorBoundary from 'Utilities/refErrorBoundary';
import { formStoreData } from 'Utilities/helpers';
import {} from 'react-router-dom';
import Landingpage from './landing';

const Landing = (props) => {
 const propShape = formStoreData(props, ['dashboard', 'agencylist', 'auth']);
 return (
  <>
   <RefProvider data={propShape}>
    <RefErrorBoundary {...props}>
     <Landingpage />
    </RefErrorBoundary>
   </RefProvider>
  </>
 );
};

export default Landing;

import { createKey } from 'Utilities/helpers';
import React, { useContext } from 'react';
import urls from 'Config/url';
import 'Styles/sidebar.css';
import { tabs } from 'Config/constants';
import { useEffect, useState } from 'react';
import ForgotPassword from '../LoginPage/forgotPassword';
import MenuComponent from './menu';
import AuthPopUp from '../LoginPage/authPopUp';
import cookie from 'react-cookies';
import Logout from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import BasicModal from './modal';
import logo from 'Assets/logo.svg';
import RefContext from 'Utilities/refContext';
import title from 'Assets/title.svg';
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar = () => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { getUser } = actions;
 const { user } = store;
 const [openPopUp, setOpenPopUp] = useState(false);
 const [authType, setAuthType] = useState('signin');
 const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
 const [windowWidth, setWindowWidth] = useState(0);
 const [anchorEl, setAnchorEl] = useState(null);
 const location = useLocation();
 const navigate = useNavigate();
 const checkUser = cookie.load('access_token');
 const listItems = [
  {
   name: checkUser ? 'Logout' : 'Login',
   icon: checkUser ? <Logout fontSize="small" /> : <LoginIcon fontSize="small" />
  }
 ];

 const forgotPasswordModalProps = {
  Content: ForgotPassword,
  setOpen: setForgotPasswordPopup,
  open: forgotPasswordPopup,
  setOpenPopUp,
  setAuthType
 };
 const authModalProps = {
  open: openPopUp,
  Content: AuthPopUp,
  authType,
  setAuthType,
  setOpen: setOpenPopUp,
  setForgotPasswordPopup
 };

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  if (!user && cookie.load('access_token')) {
   getUser();
  }
 }, [user]);

 useEffect(() => {
  const handleResize = () => {
   setWindowWidth(window.innerWidth);
  };

  handleResize();
  window.addEventListener('resize', handleResize);

  return () => {
   window.removeEventListener('resize', handleResize);
  };
 }, []);

 //********************************************************Functions***************************************************//

 const handleMenuClose = () => {
  setAnchorEl(null);
 };

 const openMenuHandler = (event) => {
  setAnchorEl(event.currentTarget);
 };

 // Redirecting to the particular router
 const handleRoute = (redirectUrl, title) => {
  if (title === 'Top Agencies') {
   localStorage?.setItem('tab', 'topAgency');
  } else {
   localStorage?.setItem('tab', 'home');
  }
  navigate(redirectUrl);
 };

 const authHandle = () => {
  if (checkUser) {
   navigate(urls?.URL_HOME);
  } else {
   navigate(urls?.URL_LANDING);
  }
 };

 //********************************************************html***************************************************//

 return (
  <>
   <BasicModal {...(forgotPasswordPopup ? forgotPasswordModalProps : authModalProps)} />
   <div className={'head-left-container'}>
    <div onClick={() => authHandle()} className="logo-container-desktop">
     <div className={'logo'}>
      <img alt="rect" src={logo} width="100%" height="100%" />
     </div>
     <div className={'app-name'}>
      <img alt="rect" src={title} width="68px" height="40px" />
     </div>
    </div>
   </div>
   <div className={'sidebar-container'}>
    <div className={'sidebar-content'}>
     {(tabs || [])?.map((data, index) => {
      const isthisActive = data.isActive(`${location.pathname}`);
      return (
       <button
        style={{
         background:isthisActive ? '#EEF2E380' : '',
         display: windowWidth > 991 && data?.title === 'Profile' ? 'none' : '',
         cursor: data?.title === 'Forum' ? 'default' : 'pointer'
        }}
        onClick={(event) =>
         data?.title === 'Profile' ? openMenuHandler(event) : handleRoute(data?.path, data?.title)
        }
        key={createKey(index, data.title)}
        className={'sidebar-tab'}
        disabled={data?.title === 'Forum'}>
        <div className={'tab-icon'}>
         {data?.title === 'Profile' && user?.profile ? (
          <img
           style={{ borderRadius: '50px' }}
           src={user?.profile}
           alt={data.title}
           width="100%"
           height="100%"
          />
         ) : (
          <img
           alt={data.title}
           src={isthisActive ? data.activeImage : data.image}
           width="100%"
           height="100%"
          />
         )}
        </div>
        <div
         style={{
          fontWeight: isthisActive ? '600' : '',
          color: data?.title === 'Forum' ? '#1F1F1F4D' : ''
         }}
         className={'tab-title'}>
         {data?.title}
         {data?.title === 'Forum' && <div className={'coming-soon'}>COMING SOON</div>}
        </div>
       </button>
      );
     })}
     <MenuComponent
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      handleClose={handleMenuClose}
      listItems={listItems}
     />
    </div>
   </div>
  </>
 );
};

export default Sidebar;

import React, { useContext, useEffect } from 'react';
import 'Styles/index.css';
import RefContext from 'Utilities/refContext';
import Sidebar from 'Components/UI/CommonComponents/sidebar';
import SidebarRight from 'Components/UI/CommonComponents/sidebarRight';
import HomeContent from 'Components/UI/HomePage/homeContent';
import { Helmet } from 'react-helmet';

const Homepage = () => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { getTopAgency, currentpage, currentFilters } = actions;
 const { topAgency } = store;

 useEffect(() => {
  getTopAgency();
 }, []);

 //********************************************************html***************************************************//

 return (
  <div className={'page-container'}>
   <Helmet>
    <title>{`Tokai | Home`}</title>
    <meta name="robots" content="noindex,nofollow" />
    <meta property="og:site_name" content={'Tokai'} />
   </Helmet>
   <div className={'page-layout-container'}>
    <div className={'page-layout'}>
     <div className={'sidebar-section'}>
      <Sidebar />
     </div>
     <div className={'main-section'}>
      <HomeContent
       topAgency={topAgency}
       currentpage={currentpage}
       currentFilters={currentFilters}
      />
      <div className={'responsive-sidebar'}>
       <Sidebar />
      </div>
     </div>
     <SidebarRight />
    </div>
   </div>
  </div>
 );
};

export default Homepage;

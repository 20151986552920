import cloneDeep from 'lodash/cloneDeep';
import { setNamespace } from 'Utilities/helpers';
import Network from 'Utilities/network';

const namespace = 'agencylist';
const createAction = setNamespace(namespace);
const nw = new Network();

// STORE
const initialState = {
 apiError: null,
 agencyList: null,
 meta: null,
};

// ACTIONS

const ASSIGN_TO_AGENCYLIST_STORE = createAction('ASSIGN_TO_AGENCYLIST_STORE');
const RESET_AGENCYLIST_STORE = createAction('RESET_AGENCYLIST_STORE');

const assignToAgencyListStore = (type, payload) => ({
 type: ASSIGN_TO_AGENCYLIST_STORE,
 meta: {
  type,
  payload
 }
});

const resetAgencyListStore = () => (dispatch) => {
 dispatch({
  type: RESET_AGENCYLIST_STORE,
  meta: {
   payload: null
  }
 });
};

// METHODS
const getAgencyList = (page, filter) => (dispatch) => {
 dispatch(assignToAgencyListStore('agencyList', null));
 return nw
  .strapiGetUrl('get_agencyList', page, filter)
  .get()
  .then((response) => {
   dispatch(assignToAgencyListStore('agencyList', response?.data));
   dispatch(assignToAgencyListStore('meta', response?.data?.meta));
   document.getElementById('main-section').scrollIntoView({ behavior: 'smooth' });
  })
  .catch((error) => {
   console.warn(error);
  });
};


//Use If need DB json
// const getAllRequetUser = () => (dispatch) => {
//   dispatch(assignToAgencyListStore("get_Posts", null));
//   return nw
//     .api("get_Posts")
//     .get()
//     .then((response) => {
//       dispatch(assignToAgencyListStore("testData", response?.data));
//     }).catch((error) => {
//       setApiError(dispatch, assignToAgencyListStore, error);
//     });
// };

// Routing

// Reducers
const agencyListReducer = (state = initialState, action) => {
 const localState = cloneDeep(state);

 switch (action.type) {
  case ASSIGN_TO_AGENCYLIST_STORE:
   localState[action.meta.type] = action.meta.payload;
   return { ...localState };
  case RESET_AGENCYLIST_STORE:
   return initialState;
  default:
   return localState;
 }
};

export default {
 namespace,
 store: initialState,
 reducer: agencyListReducer,
 creators: {
  assignToAgencyListStore,
  resetAgencyListStore,
  getAgencyList,
  
 }
};

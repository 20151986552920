import React, { useContext, useEffect, useRef, useState } from 'react';
import 'Styles/projectDetails.css';
import Button from '../CommonComponents/button';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { createKey } from 'Utilities/helpers.js';
import urls from 'Config/url';
import downArrow from 'Assets/downArrow.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import RefContext from 'Utilities/refContext';
import search from 'Assets/search.svg';
import cookie from 'react-cookies';
import warning from 'Assets/WarningCircle.svg';

const ProjectDetailsForm = ({ setOpen, setVerificationModalOpen }) => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { postProjectDetails, getUser, getTopAgency, getFilters } = actions;
 const { user, topAgency, filters } = store;

 const [details, setDetails] = useState([]);
 const dropdown = useRef(null);
 const dropdownListRef = useRef(null);
 const navigate = useNavigate();
 const location = useLocation();
 const [openIndex, setOpenIndex] = useState(null);
 const [searchTerm, setSearchTerm] = useState('');
 const [selectedIndex, setSelectedIndex] = useState(-1);
 const [error, setError] = useState(null);
 const [textCount, setTextCount] = useState(-300);

 const formData = [
  {
   title: 'Project Domain',
   options: filters?.data?.attributes?.Domain?.split(',')?.map((domain) => {
    return { name: domain };
   })
  },
  {
   title: 'Type of Service',
   options: topAgency?.topAgencies_category?.map((category) => {
    return { name: category?.category };
   })
  },
  {
   title: 'Your Maximum Budget',
   options: filters?.data?.attributes?.MinProjectSize?.slice(1, -1)
    ?.split("','")
    ?.map((minProjectSize) => {
     return { name: minProjectSize };
    })
  }
 ];

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  if (!user && cookie.load('access_token')) {
   getUser();
  }
  if (!topAgency) {
   getTopAgency();
  }
  if (!filters) {
   getFilters();
  }
 }, []);

 //Handles keyboard funcitonality
 useEffect(() => {
  const handleKeyDown = (event) => {
   if (openIndex !== null && formData[openIndex]?.options?.length > 0) {
    switch (event.key) {
     case 'ArrowUp':
      setSelectedIndex((prevIndex) =>
       prevIndex > 0 ? prevIndex - 1 : formData[openIndex]?.options?.length - 1
      );
      event.preventDefault();
      break;
     case 'ArrowDown':
      setSelectedIndex((prevIndex) =>
       prevIndex < formData[openIndex]?.options?.length - 1 ? prevIndex + 1 : 0
      );
      event.preventDefault();
      break;
     case 'Enter':
      if (selectedIndex >= 0 && selectedIndex < formData[openIndex]?.options?.length) {
       handleFilterSelect(
        formData[openIndex].options[selectedIndex].name,
        formData[openIndex].title
       );
       event.preventDefault();
      }
      break;
     case 'Tab':
      if (openIndex === null) {
       setOpenIndex(0);
       setSelectedIndex(0);
      } else {
       setOpenIndex(null);
      }
      event.preventDefault();
      break;
     case 'Escape':
      setOpenIndex(null);
      setSelectedIndex(-1);
      event.preventDefault();
      break;
     default:
      break;
    }
   }
  };

  document.addEventListener('keydown', handleKeyDown);
  return () => {
   document.removeEventListener('keydown', handleKeyDown);
  };
 }, [selectedIndex, openIndex]);

 //Scrolls to the option which is highlighted while using the keyboard functionality
 useEffect(() => {
  if (dropdownListRef.current) {
   const selectedElement = dropdownListRef.current.children[selectedIndex];
   if (selectedElement) {
    selectedElement.scrollIntoView({
     behavior: 'smooth',
     block: 'nearest'
    });
   }
  }
 }, [selectedIndex]);

 //Close the dropdown while clicking outside the dropdown
 useEffect(() => {
  const handleOutSideClick = (event) => {
   if (!dropdown.current?.contains(event.target)) {
    setOpenIndex(null);
   }
  };
  window.addEventListener('mousedown', handleOutSideClick);
  return () => {
   window.removeEventListener('mousedown', handleOutSideClick);
  };
 }, []);

 //********************************************************Functions***************************************************//

 // To open the dropdown of the specific category field
 const handleDropdown = (index) => {
  setOpenIndex(openIndex === index ? null : index);
  setSelectedIndex(-1);
  setSearchTerm('');
 };

 // To store the search input in state
 const handleSearchChange = (event) => {
  setSearchTerm(event.target.value);
 };
 // Storing multiple selected category values in state
 const handleFilterSelect = (item, name) => {
  setDetails((prevDetails) => ({
   ...prevDetails,
   [name]: item
  }));
  setError((prevDetails) => ({
   ...prevDetails,
   [name]: ''
  }));
  setOpenIndex(null);
  setSelectedIndex(-1);
 };

 // Form validation and submission
 const handleSubmit = () => {
  let hasError = false;

  if (!details['Project Domain']) {
   setError((prevDetails) => ({
    ...prevDetails,
    'Project Domain': 'Please choose one'
   }));
   hasError = true;
  } else {
   setError((prevDetails) => ({
    ...prevDetails,
    'Project Domain': ''
   }));
  }

  if (!details['Type of Service']) {
   setError((prevDetails) => ({
    ...prevDetails,
    'Type of Service': 'Please choose one'
   }));
   hasError = true;
  } else {
   setError((prevDetails) => ({
    ...prevDetails,
    'Type of Service': ''
   }));
  }

  if (!details['Your Maximum Budget']) {
   setError((prevDetails) => ({
    ...prevDetails,
    'Your Maximum Budget': 'Please choose your budget'
   }));
   hasError = true;
  } else {
   setError((prevDetails) => ({
    ...prevDetails,
    'Your Maximum Budget': ''
   }));
  }

  if (!details['description'] || details['description'].length < 50) {
   setError((prevDetails) => ({
    ...prevDetails,
    description: 'Please add minimum of 50 characters'
   }));
   hasError = true;
  } else {
   setError((prevDetails) => ({
    ...prevDetails,
    description: ''
   }));
  }

  if (!hasError) {
   postProjectDetails({
    data: {
     userID: String(user?.id),
     username: user?.username,
     userEmail: user?.email,
     projectDomain: details['Project Domain'],
     serviceType: details['Type of Service'],
     maxBudget: details['Your Maximum Budget'],
     phoneNumber: details?.phone_number,
     projectDescription: details?.description
    }
   });

   if (location?.pathname === '/project-detail') {
    setVerificationModalOpen(true);
   } else {
    setOpen(false);
    setVerificationModalOpen(true);
   }
  }
 };

 //Description field validation
 const handleDescriptionChange = (event) => {
  setTextCount(-300 + event.target.value?.length);
  if (!event.target.value) {
   setError({
    description: 'Description is required'
   });
  } else {
   setDetails((prevDetails) => ({
    ...prevDetails,
    description: event?.target?.value
   }));
   setError((prevDetails) => ({
    ...prevDetails,
    description: ''
   }));
  }
 };

 //Phone number validation
 const handlePhoneNumber = (newValue) => {
  if (!newValue) {
   setError((prevDetails) => ({
    ...prevDetails,
    phone_number: ''
   }));
  } else if (newValue?.length > 0 && !isValidPhoneNumber(newValue)) {
   setError((prevDetails) => ({
    ...prevDetails,
    phone_number: 'Invalid phone number'
   }));
  } else {
   setDetails((prevDetails) => ({
    ...prevDetails,
    phone_number: newValue
   }));
   setError((prevDetails) => ({
    ...prevDetails,
    phone_number: ''
   }));
  }
 };

 const handleRoutes = () => {
  if (location.pathname === urls.URL_PROJECTBRIEF) {
   navigate(urls?.URL_HOME);
  } else {
   setOpen(false);
  }
 };

 //********************************************************html***************************************************//

 return (
  <div className={'project-detail-layout'}>
   <div className={'project-detail-container'}>
    <div className={'form-section'}>
     <div className={'title-section'}>
      <div className={'project-brief-title'}>Tell us about your project</div>
      <div className={'project-brief-description'}>
       We'll find the best software development agencies for you.
      </div>
     </div>
     <div className={'form-content'}>
      <div ref={dropdown} className={'form-container'}>
       {(formData || [])?.map((category, index) => {
        const filteredOptions = category?.options?.filter((item) =>
         item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return (
         <div key={createKey(index, category.title)} className={'project-brief-form-field'}>
          <div className={'project-brief-field-name-container'}>
           <div className={'project-brief-field-name'}>{category?.title}</div>
          </div>
          <div className={'project-brief-dropdown-field'}>
           <button
            onClick={() => handleDropdown(index)}
            className={'project-brief-select-field-container'}>
            <div className={'project-brief-select-field'}>
             <div
              style={{
               color: details[category?.title] ? '#1A202C' : ''
              }}
              className={'project-brief-dropdown-field-name'}>
              {details[category?.title] ? details[category?.title] : 'Choose one'}
             </div>
             <div
              className={'arrow-icon'}
              style={{
               transform: openIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
               transition: 'transform 0.3s ease'
              }}>
              <img alt="arrow" src={downArrow} width={20} height={20} />
             </div>
            </div>
           </button>
           {error && error[`${category?.title}`] && (
            <div className={'error-message-container'}>
             <div className={'error-message-image'}>
              <img src={warning} alt="warning" />
             </div>
             <div style={{ color: 'red', fontSize: 'small' }}>{error[`${category?.title}`]}</div>
            </div>
           )}
           {openIndex === index && (
            <div className={'project-brief-dropdown-container'}>
             <div className={'project-brief-dropdown-content'}>
              <div className={'project-brief-search-field-container'}>
               <div className={'search-field'}>
                <div className={'arrow-icon'}>
                 <img alt="search" src={search} width={20} height={20} />
                </div>
                <input
                 placeholder="Search"
                 className={'input-field'}
                 value={searchTerm}
                 onChange={handleSearchChange}
                />
               </div>
              </div>
              <div ref={dropdownListRef} className={'dropdown-list'}>
               {filteredOptions?.length > 0 ? (
                filteredOptions.map((options, i) => (
                 <div
                  key={createKey(i, options.name)}
                  className={`${'project-brief-dropdown-field-container'} ${
                   i === selectedIndex ? 'selected' : ''
                  }`}
                  onClick={() => handleFilterSelect(options.name, category.title)}>
                  <div className={'search-field'}>
                   <div
                    className={`project-brief-dropdown-option-name ${
                     i === selectedIndex ? 'hovered' : ''
                    }`}>
                    {options.name}
                   </div>
                   <input
                    type="radio"
                    id={options.name}
                    value={options.name}
                    checked={details[category.title] === options.name}
                    onChange={() => handleFilterSelect(options.name, category.title)}
                   />
                  </div>
                 </div>
                ))
               ) : (
                <div className={'project-brief-dropdown-field-container'}>
                 <div className={'search-field'}>
                  <div className={'project-brief-dropdown-option-name'}>No results found</div>
                 </div>
                </div>
               )}
              </div>
             </div>
            </div>
           )}
          </div>
         </div>
        );
       })}
       <div className={'field-container'}>
        <div className={'project-field-name-container'}>
         <div className={'project-field-name'}>
          Phone no. <span>(optional)</span>
         </div>
        </div>
        <div className={'search-field-container'}>
         <div className={'select-field'}>
          <PhoneInput
           placeholder="0000 0000"
           value={details?.phone_number}
           onChange={handlePhoneNumber}
           defaultCountry="IN"
          />
         </div>
        </div>
       </div>
       {error?.phone_number && (
        <div className={'error-message-container'}>
         <div className={'error-message-image'}>
          <img src={warning} alt="warning" />
         </div>
         <div style={{ color: 'red', fontSize: 'small' }}>{error?.phone_number}</div>
        </div>
       )}
       <div className={'field-container'}>
        <div className={'field-name-container'}>
         <div className={'project-field-name'}>Tell us few words about your project</div>
        </div>
        <div className={'input-field-container'}>
         <textarea
          className={'textarea-field'}
          placeholder="Give us little brief here and we can get you the right agency"
          maxLength="300"
          onChange={(e) => handleDescriptionChange(e)}></textarea>
         <div className="textarea-wordcount">{textCount}</div>
        </div>
       </div>
       {error?.description && (
        <div className={'error-message-container'}>
         <div className={'error-message-image'}>
          <img src={warning} alt="warning" />
         </div>
         <div style={{ color: 'red', fontSize: 'small' }}>{error?.description}</div>
        </div>
       )}
      </div>
      <div onClick={handleSubmit}>
       <Button.Primary bgcolor="var(--primary-color)" content="Submit my Brief" />
      </div>
     </div>
     {location?.pathname === urls?.URL_PROJECTBRIEF && (
      <div className={'back-content'}>
       <button className={'skip-content'} onClick={() => handleRoutes()}>
        I will do this later
       </button>
      </div>
     )}
    </div>
   </div>
  </div>
 );
};

export default ProjectDetailsForm;

import React, { useEffect, useState, useContext } from 'react';
import RefContext from 'Utilities/refContext';
import 'Styles/resetPassword.css';
import Button from '../CommonComponents/button';
import ShieldImage from 'Assets/shield.png';
import urls from 'Config/url';
import eyeClose from 'Assets/eyeClose.svg';
import eyeOpen from 'Assets/eyeOpen.svg';

const ResetPassword = ({ location }) => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { actions } = ctx;
 const { resetPassword } = actions;
 const queryParams = new URLSearchParams(location.search);
 const token = queryParams.get('token');
 const [newPassword, setNewPassword] = useState({
  password_1: '',
  password_2: ''
 });
 const [eyeIconOpen, setEyeIconOpen] = useState({
  icon_1: false,
  icon_2: false
 });
 const [errorText, setErrorText] = useState('');
 const [isSuccess, setIsSuccess] = useState(false);

 //********************************************************Functions***************************************************//

 //Reset password validation and functionality
 const handleResetButton = () => {
  if (newPassword?.password_1?.length >= 8) {
   if (newPassword?.password_1 === newPassword?.password_2) {
    setErrorText('');
    resetPassword(
     {
      token: token,
      newPassword: newPassword?.password_1
     },
     setNewPassword,
     newPassword,
     setIsSuccess
    );
   } else {
    setErrorText('password mismatch');
   }
  } else {
   setErrorText('min 8 characters');
  }
 };

 const handleResetSuccess = () => {
  window.open(urls.URL_AUTH, '_blank', 'noopener,noreferrer');
 };

 //********************************************************html***************************************************//

 return (
  <div className="reset-wrapper">
   {isSuccess ? (
    <div className="reset-success-wrapper">
     <div className="reset-title">Password updated successfully!</div>
     <div className="reset-success-image">
      <img src={ShieldImage} alt="success" width="100%" height="100%" />
     </div>
     <div className="reset-success-description">
      Your password was successfully upadted! To ensure your account's security, you will need to
      log in to the application with updated credentials.{' '}
     </div>
     <div
      className="reset-button"
      onClick={() => {
       handleResetSuccess();
      }}>
      <Button.Primary
       bgcolor="var(--primary-color)"
       content={
        <div className={'auth-button-container'}>
         <div className={'auth-btn-title'}>Login</div>
        </div>
       }
      />
     </div>
    </div>
   ) : (
    <>
     <div className="reset-title-content">
      <div className="reset-title">Create new password</div>
      <div className="reset-title-description">Enter your new password to complete the reset.</div>
     </div>
     <div className="reset-inputs-and-buttons">
      <div className="reset-input-fields">
       <div className="reset-password-section">
        <span>New password</span>
        <div className="reset-input-and-error">
         <div className="reset-password-field">
          <input
           placeholder="Enter new password"
           className="reset-input"
           maxLength={30}
           type={eyeIconOpen?.icon_1 ? 'text' : 'password'}
           value={newPassword?.password_1}
           onChange={(e) => {
            setNewPassword({
             ...newPassword,
             password_1: e.target.value
            });
           }}
          />
          <div
           className={'eye-icon-container'}
           onClick={() => {
            setEyeIconOpen((prevState) => ({
             ...prevState,
             icon_1: !prevState.icon_1
            }));
           }}
           data-testid="toggle-password-visibility">
           <img
            alt="showIcon"
            src={eyeIconOpen?.icon_1 ? eyeOpen : eyeClose}
            width={16}
            height={16}
           />
          </div>
         </div>
         <span className="reset-password-error">
          <>{errorText !== 'password mismatch' && errorText}</>
         </span>
        </div>
       </div>
       <div className="reset-password-section">
        <span>Confirm password</span>
        <div className="reset-input-and-error">
         <div className="reset-password-field">
          <input
           placeholder="Enter confirm password"
           className="reset-input"
           maxLength={30}
           type={eyeIconOpen?.icon_2 ? 'text' : 'password'}
           value={newPassword?.password_2}
           onChange={(e) => {
            setNewPassword({
             ...newPassword,
             password_2: e.target.value
            });
           }}
          />
          <div
           className={'reset-eye-icon'}
           onClick={() => {
            setEyeIconOpen((prevState) => ({
             ...prevState,
             icon_2: !prevState.icon_2
            }));
           }}
           data-testid="toggle-password-visibility">
           <img
            alt="showIcon"
            src={eyeIconOpen?.icon_2 ? eyeOpen : eyeClose}
            width={16}
            height={16}
           />
          </div>
         </div>
         <span className="reset-password-error">
          <>{errorText !== 'min 8 characters' && errorText}</>
         </span>
        </div>
       </div>
      </div>
      <div className={'reset-button'} onClick={() => handleResetButton()}>
       <Button.Primary
        bgcolor="var(--primary-color)"
        content={
         <div className={'auth-button-container'}>
          <div className={'auth-btn-title'}>Reset password</div>
         </div>
        }
       />
      </div>
     </div>
    </>
   )}
  </div>
 );
};

export default ResetPassword;

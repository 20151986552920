import React from 'react';
import 'Styles/agencyDetails.css';
import { createKey } from 'Utilities/helpers';
import suitCaseIcon from 'Assets/suitcaseSimple.svg';
import usersThreeIcon from 'Assets/usersThree.svg';
import handshakeIcon from 'Assets/handshake.svg';
import gpsIcon from 'Assets/gpsFix.svg';
import dollar from 'Assets/currencyCircleDollar.svg';
import calendar from 'Assets/calendarBlank.svg';
import building from 'Assets/building.svg';

const KeyInfo = ({ agencyDetails }) => {
 //********************************************************Constants***************************************************//

 const keyInfo = [
  { icon: dollar, content: agencyDetails?.hourlyRate ? agencyDetails?.hourlyRate : '' },
  { icon: usersThreeIcon, content: agencyDetails?.employees ? agencyDetails?.employees : '' },
  {
   icon: building,
   content: agencyDetails?.yearOfFoundation ? `Since ${agencyDetails?.yearOfFoundation}` : ''
  },
  { icon: suitCaseIcon, content: agencyDetails?.clientFocus ? agencyDetails?.clientFocus : '' },
  {
   icon: handshakeIcon,
   content: agencyDetails?.minProjectSize
    ? agencyDetails?.minProjectSize === '<$5,000'
      ? 'Max $5,000'
      : `Min ${agencyDetails?.minProjectSize}`
    : ''
  },
  {
   icon: gpsIcon,
   content: agencyDetails?.locations[0]?.countryAndCity
    ? agencyDetails?.locations[0]?.countryAndCity
    : ''
  },
  { icon: calendar, content: agencyDetails?.readyToStart ? agencyDetails?.readyToStart : '' }
 ];

 //********************************************************html***************************************************//

 return (
  <div className="right-container" style={{ position: 'sticky', top: '108px' }}>
   <div className="right-key-title">Key Information</div>
   <div className="right-key-value-container">
    {keyInfo &&
     keyInfo?.map((keys, index) => (
      <div key={createKey(index, keys?.content)} className="key-value-content">
       <div className="key-image">
        <img alt="key" src={keys?.icon} width="100%" height="100%" />
       </div>
       <div className="key-value">{keys?.content}</div>
      </div>
     ))}
   </div>
  </div>
 );
};
export default KeyInfo;

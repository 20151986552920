import React, { useContext, useEffect } from 'react';
import 'Styles/LandingPage/landing.css';
import { createKey } from 'Utilities/helpers';
import logo from 'Assets/logo.svg';
import title from 'Assets/light-title.svg';
import { useNavigate } from 'react-router-dom';
import urls from 'Config/url';
import RefContext from 'Utilities/refContext';

const LanidngFooter = () => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { getTopAgency } = actions;
 const { topAgency } = store;
 const navigate = useNavigate();

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  if (!topAgency) {
   getTopAgency();
  }
 }, []);

 //********************************************************Function***************************************************//

 //Navigate to the agency list under a particular category
 const handleRouter = (category) => {
  navigate(`/${category?.slug}`, {
   state: { category: category?.category, title: category?.title }
  });
 };

 //********************************************************html***************************************************//

 return (
  <div className="landingpage-footer-container">
   <div className="landingpage-topservice-title">Top Services</div>
   <div className="landingpage-footer-category">
    {topAgency?.topAgencies_category?.map((category, index) => (
     <div key={createKey(index, category?.title)} className="landingpage-footer-category-tags">
      <div className="footer-category-name" onClick={() => handleRouter(category)}>
       {category?.category}
      </div>
     </div>
    ))}
   </div>
   <div className="landing-footer-end-content">
    <div className="footer-end-container">
     <div className="footer-logo-container">
      <div className="landing-header-logo-container">
       <div className="landing-header-logo">
        <img src={logo} alt="tokai" width="100%" height="100%" />
       </div>
       <div className="landing-app-name">
        <img src={title} alt="tokai" width="100%" height="100%" />
       </div>
      </div>
      <div className="footer-cc">ⓒ 2024 tokai.work</div>
     </div>
     <div className="landing-navigation-tabs">
      <button className="landing-tabs" onClick={() => navigate(urls?.URL_HOME)}>
       Home
      </button>
      <button
       className="landing-tabs"
       onClick={() => {
        navigate(urls?.URL_TOPAGENCY), localStorage?.setItem('tab', 'topAgency');
       }}>
       Top agencies
      </button>
      <button disabled={true} className="landing-tabs-forum" style={{ color: '#FFFFFF66' }}>
       Forum
      </button>
      <button className="landing-tabs">Contact us</button>
     </div>
    </div>
   </div>
  </div>
 );
};
export default LanidngFooter;

import React from 'react';
import 'Styles/LandingPage/landingSection.css';
import Browse from 'Assets/Landing/browse.png';
import Interview from 'Assets/Landing/interview.png';
import Onboard from 'Assets/Landing/onboard.png';
import RightArrow from 'Assets/Landing/Vector.png';
import { createKey } from 'Utilities/helpers';
import Button from '../CommonComponents/button';
import { useNavigate } from 'react-router-dom';
import urls from 'Config/url';
import { onImageError } from 'Utilities/helpers';

const LandingHiringByDuration = () => {
 //********************************************************Constants***************************************************//

 const navigate = useNavigate();

 const cardDetails = [
  {
   image: Browse,
   title_1: 'Browse',
   title_2: ' agency profiles',
   description:
    'Start now by filling out a short form and access pre-vetted, ready-to-interview development agencies.'
  },
  {
   image: Interview,
   title_1: 'Interview',
   title_2: ' in 1-3 days',
   description:
    'Share your project details, and we’ll provide 2-3 expertly matched agencies within 48 hours, ready to meet.'
  },
  {
   image: Onboard,
   title_1: 'Onboard',
   title_2: ' in 1 week',
   description:
    'Get your agency onboarded immediately, while we handle contracts, payments, and ongoing project management.'
  }
 ];

 //********************************************************Function***************************************************//

 //Navigate to login page
 const handleRoute = () => {
  navigate(urls.URL_AUTH);
 };

 //********************************************************html***************************************************//

 return (
  <div className="duration-box">
   <div className="duration-wrapper">
    <div className="duration-heading">
     <div className="duration-title-1">Hiring through Tokai</div>
     <div className="duration-title-2">
      <span>takes days</span>, not months
     </div>
    </div>
    <div className="duration-card-container">
     <div className="duration-card-wrapper">
      {cardDetails?.map((data, index) => {
       return (
        <React.Fragment key={createKey(index, data?.description)}>
         <div className="duration-details">
          <div className="duration-details-image">
           <img
            onError={onImageError}
            src={data?.image}
            alt="duration"
            width="100%"
            height="100%"
           />
          </div>
          <div className="duration-text-content">
           <div className="duration-details-title">
            <span>{data?.title_1}</span>
            {data?.title_2}
           </div>
           <div className="duration-details-description">{data?.description}</div>
          </div>
         </div>
         {index !== 2 && (
          <div className="duration-rightArrow-content">
           <div className="duration-rightArrow-icon">
            <img src={RightArrow} alt="right-arrow" width="100%" height="100%" />
           </div>
           <div className="duration-rightArrow-icon">
            <img src={RightArrow} alt="right-arrow" width="100%" height="100%" />
           </div>
          </div>
         )}
        </React.Fragment>
       );
      })}
     </div>
     <div></div>
    </div>
    <div className="duration-nav-Button" onClick={handleRoute}>
     <Button.Primary content="Hire with Confidence" hoverType="hover2" textColor="#FFFFFF" />
    </div>
   </div>
  </div>
 );
};

export default LandingHiringByDuration;

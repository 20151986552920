import React from 'react';
import { useForm } from 'react-hook-form';
import 'Styles/forgotPassword.css';
import { useEffect, useState } from 'react';
import eyeClose from 'Assets/eyeClose.svg';
import eyeOpen from 'Assets/eyeOpen.svg';
import { checkValidMail } from 'Utilities/helpers';
import Button from '../CommonComponents/button';
import EastIcon from '@mui/icons-material/East';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthForm = ({ authType, setForgotPasswordPopup, setMessage, message, login }) => {
 //********************************************************Constants***************************************************//

 const [showPassword, setShowPassword] = useState(true);
 let navigate = useNavigate();
 let location = useLocation();
 const {
  register,
  handleSubmit,
  reset,
  formState: { errors }
 } = useForm();

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  reset();
 }, [authType]);

 //********************************************************Functions***************************************************//

 // Login api integration
 const handlelogin = async (data, sso = false) => {
  login(data, navigate, location);
 };

 // Pass credentials to login or signup api
 const onSubmit = (data) => {
  if (authType === 'signin') {
   handlelogin(data);
  }
 };

 //********************************************************html***************************************************//

 return (
  <form
   onSubmit={handleSubmit(onSubmit)}
   className={'forgot-password-container'}
   data-testid="auth-form">
   <div className={'email-field'}>
    <div className={'field-name'}>E-mail</div>
    <div className={'field'}>
     <input
      type="text"
      className={'input-field-login'}
      placeholder="example@gmail.com"
      maxLength="50"
      {...register('email', {
       onChange: () => {
        setMessage('');
       },
       validate: {
        email: (v) => checkValidMail(v)
       },
       required: true
      })}
      data-testid="email-input"></input>
     {errors?.email?.type === 'required' && (
      <span className={'input-error'}>This field is required</span>
     )}
     {errors?.email?.type === 'email' && <span className={'input-error'}>Invalid email</span>}
     {message && <span className={'input-error'}>{message}</span>}
    </div>
   </div>
   <div className={'email-field'}>
    <div className={'field-name-container'}>
     <div className={'field-name'}>Password</div>
     <button
      type="button"
      onClick={() => {
       setForgotPasswordPopup(true);
      }}
      className={'forgot-password-link'}>
      Forgot password ?
     </button>
    </div>

    <div className={'field'}>
     <input
      type={showPassword ? 'password' : 'text'}
      className={'input-field-login password-field'}
      name="password"
      maxLength="30"
      placeholder="Minimum 8 characters"
      {...register('password', { required: true, minLength: 8 })}
      data-testid="password-input"
     />
     <div
      className={'eye-icon-container'}
      onClick={() => setShowPassword(!showPassword)}
      data-testid="toggle-password-visibility">
      <img  alt="showIcon" src={!showPassword ? eyeOpen : eyeClose} width={16} height={16} />
     </div>
     {errors?.password?.type === 'required' && (
      <span className={'input-error'}>This field is required</span>
     )}
     {errors?.password?.type === 'minLength' && (
      <span className={'input-error'}>min 8 characters</span>
     )}
    </div>
   </div>
   <div style={{ paddingTop: '16px', width: '100%' }}>
    <Button.Primary
     bgcolor="var(--primary-color)"
     content={
      <div className={'auth-button-container'}>
       <div className={'auth-btn-title'}>
        {' '}
        {location && location?.state?.isLoggin ? 'Join now' : `Continue & Add my Breif`}
        {location && !location?.state?.isLoggin && <EastIcon fontSize="small" />}
       </div>
      </div>
     }
    />
   </div>
  </form>
 );
};

export default AuthForm;

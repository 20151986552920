// Controllers
import Home from 'Pages/Home';
import AgencyLists from 'Pages/AgencyList';
import TopAgency from 'Pages/TopAgency';
import Login from 'Pages/Login';
import Agency from 'Pages/Agency';
import ProjectDetailForm from 'Pages/ProjectDetailForm';
import Landing from 'Pages/Landing';

function Injector(component, name) {
 const hoc = component;
 hoc.displayName = name;

 return hoc;
}

export default {
 Home: Injector(Home, 'Home'),
 AgencyLists: Injector(AgencyLists, 'AgencyList'),
 TopAgency: Injector(TopAgency, 'TopAgency'),
 Login: Injector(Login, 'Login'),
 Agency: Injector(Agency, 'Agency'),
 ProjectDetailForm: Injector(ProjectDetailForm, 'ProjectDetailForm'),
 Landing: Injector(Landing, 'Landing')
};

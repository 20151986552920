import React, { useContext, useEffect, useState } from 'react';
import 'Styles/LandingPage/landingSection.css';
import RefContext from 'Utilities/refContext';
import { useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import Button from '../CommonComponents/button';
import ProjectDetailsForm from '../ProjectBriefPage/projectDetails';
import Verification from '../ProjectBriefPage/verificationModal';
import BasicModal from '../CommonComponents/modal';
import urls from 'Config/url';
import { onImageError } from 'Utilities/helpers';

const ExpertiseSkillsCards = ({ pageName }) => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { getFilters } = actions;
 const { filters } = store;
 const navigate = useNavigate();
 const [storeCardData, setStoreCardData] = useState();

 const checkUser = cookie.load('access_token');
 const [projectDetailModalOpen, setProjectDetailModalOpen] = useState(false);
 const [openPopUp, setOpenPopUp] = useState(false);
 const [authType, setAuthType] = useState('signin');
 const [verificationModalOpen, setVerificationModalOpen] = useState(false);

 const projectDetailsModalProps = {
  Content: ProjectDetailsForm,
  setOpen: setProjectDetailModalOpen,
  open: projectDetailModalOpen,
  setVerificationModalOpen,
  setOpenPopUp,
  setAuthType
 };
 const verificationModalProps = {
  Content: Verification,
  setOpen: setVerificationModalOpen,
  open: verificationModalOpen,
  setOpenPopUp,
  setAuthType
 };

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  getFilters();
 }, [pageName]);

 useEffect(() => {
  setStoreCardData(filters?.data?.attributes);
 }, [filters]);

 //********************************************************Functions***************************************************//

 //Navigate to the agency list under a particular category
 const handleRouter = (title, filter, slug) => {
  if (filter) {
   navigate(`/${slug}`, {
    state: {
     category: filter,
     title: title
    }
   });
   window?.location?.reload();
  }
 };

 //********************************************************html***************************************************//

 return (
  <div className="expertise-box">
   <div className="expertise-wrapper">
    <div className="expertise-heading">
     <div className="expertise-title-1">Hire</div>
     <div className="expertise-title-2">
      {pageName === 'expertise' ? 'Agencies Expertise' : 'Agencies by Skill'}
     </div>
    </div>
    <div className="expertise-cards-container">
     <div className="expertise-cards-wrapper">
      {(pageName === 'expertise' && storeCardData
       ? storeCardData?.ExpertiseDetails
       : storeCardData?.SkillsDetails || []
      )?.map((data, index) => {
       return (
        <div
         className="expertise-card"
         key={index}
         style={{ cursor: data?.filter && 'pointer' }}
         onClick={() => handleRouter(data?.name, data?.filter, data?.slug)}>
         <div className={pageName === 'expertise' ? 'expertise-icon' : 'skills-icon'}>
          <img
           onError={onImageError}
           src={data?.icon}
           alt="expertise agencies"
           width="100%"
           height="100%"
          />
         </div>
         <div className="expertise-name">{data?.name}</div>
        </div>
       );
      })}
      <div className="expertise-card">
       <div className="expertise-skills-query">Have a brief? Let us know</div>
       <div
        className="expertise-skills-button"
        onClick={() => handleRouter(data?.name, data?.filter, data?.slug)}>
        <Button.Primary
         content="Get an Agency"
         bgcolor="transparent"
         textColor="rgba(169, 240, 5, 1)"
        />
       </div>
      </div>
     </div>
    </div>
   </div>
   {verificationModalOpen && <BasicModal {...verificationModalProps} />}
   {projectDetailModalOpen && <BasicModal {...projectDetailsModalProps} />}
  </div>
 );
};

export default ExpertiseSkillsCards;

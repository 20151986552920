import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import RefContext from 'Utilities/refContext';
import 'Styles/index.css';
import AgencyDetails from 'Components/UI/AgencyProfilePage/agencyDetails';
import Sidebar from 'Components/UI/CommonComponents/sidebar';
import MainContentHeader from 'Components/UI/CommonComponents/mainContentHeader';
import SidebarRight from 'Components/UI/CommonComponents/sidebarRight';
import KeyInfo from 'Components/UI/AgencyProfilePage/keyInfo';
import { Helmet } from 'react-helmet';

const AgencyDetail = () => {
 //********************************************************Constants***************************************************//

 const ctx = useContext(RefContext);
 const { store, actions } = ctx;
 const { getAgency, getTopAgency } = actions;
 const { agency, topAgency } = store;
 const [userid, setUserId] = useState(null);
 const [searchInput, setSearchInput] = useState('');
 const { companyName } = useParams();

 //********************************************************UseEffect***************************************************//

 useEffect(() => {
  getAgency(companyName);
  if (!topAgency) {
   getTopAgency();
  }
 }, []);

 //********************************************************html***************************************************//

 return (
  <div className={'page-container'}>
   <Helmet>
    <title>{`Tokai | ${agency?.companyName ? agency?.companyName : 'Loading..'}`}</title>
    <meta name="robots" content="noindex,nofollow" />
    <meta name="description" content={agency?.description} />
    <meta property="og:site_name" content={'Tokai'} />
   </Helmet>
   <>
    <div className={'page-layout-container'}>
     <div className={'page-layout'}>
      <div className={'sidebar-section'}>
       <Sidebar setUserId={setUserId} userid={userid} />
      </div>
      <div className={'main-section'}>
       <MainContentHeader topAgency={topAgency} />
       <AgencyDetails agencyDetails={agency} userId={userid} />
       <div className={'responsive-sidebar'}>
        <Sidebar />
       </div>
      </div>
      <SidebarRight
       Content={<KeyInfo agencyDetails={agency} />}
       setUserId={setUserId}
       setSearchInput={setSearchInput}
       searchInput={searchInput}
      />
     </div>
    </div>
   </>
  </div>
 );
};
export default AgencyDetail;

import React from 'react';

import RefProvider from 'Utilities/refProvider';
import RefErrorBoundary from 'Utilities/refErrorBoundary';
import { formStoreData } from 'Utilities/helpers';
import ProjectDetail from './ProjectDetailForm';

const ProjectDetailForm = (props) => {
 const propShape = formStoreData(props, ['agencylist', 'dashboard','auth']);
 return (
  <>
   <RefProvider data={propShape}>
    <RefErrorBoundary {...props}>
     <ProjectDetail />
    </RefErrorBoundary>
   </RefProvider>
  </>
 );
};

export default ProjectDetailForm;
